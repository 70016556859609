import { createTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { green, orange } from '@mui/material/colors';
 

const primaryTheme = createTheme({
    palette: {
      primary: {
        main: "#0b0b0b",        
        font: "#212121",
        halfOpacity: "rgba(33, 33, 33, 0.5)",
      },
      secondary: {
        main: "#f9a825"
      },
      naturalChange: {        
        main: "#00897b"
      },
      landclearing: {
        main: "#03A9F4"
      },    
      newConstruction: {
        main: "#f9a825"
      },
      renovation: {
        main: "#9c27b0"
      }, 
      falsePositive: {
        main: "#212121",
        contrastText: "#ffffff" //button text white instead of black
      },
      white: {
        main: "#ffffff",
        font: "#ffffff"
      },
      black: {
        main: "#212121",
        font: "#212121"
      },
      red: {
        main: "#f44336",
        contrastText: "#ffffff"
      },
      green: {
        main: '#4CAF50'
      },
      lot: {
        main: "#4CAF50"
      },
      gray: {
        main: "#BDBDBD",
        contrastText: "#6c6c6c"
      },
      disabled: {
        main: "#BDBDBD"
      },
      google: {
        main: "#E34133",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      visa: {
        main: "#1A1F71",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      mastercard: {
        main: "#FF5F00",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      amex: {
        main: "#0078A8",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      discover: {
        main: "#FF5F00",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      stripeGreen: {
        main: "#4CAF50",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      stripeYellow: {
        main: "#FFA000",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      stripeRed: {
        main: "#F44336",
        font: '#ffffff',
        contrastText: "#ffffff",
      },
      stripeGray: {
        main: "#9E9E9E",
        font: '#ffffff',
        contrastText: "#ffffff",
      }      

    },
    components: {
      MuiSlider: {
        styleOverrides: {
          mark: {
            backgroundColor: '#ffffff', 
            width: '4px',
            height: '4px',
            borderRadius: '50%',
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: '#f9a825'
          },
        },
      },
    },
  
  });
  
const darkTheme = createTheme({
    palette: {
      primary: {
        main: orange[500],
      },
    },
  });
  


export { darkTheme, primaryTheme }