
export function polygonToBounds(polygon) {
  const bounds = {
    north: -90,
    south: 90,
    east: -180,
    west: 180,
  }
  polygon.forEach(element => {
    const lat = element.lat;
    const lng = element.lng;
    bounds.north = Math.max(bounds.north, lat);
    bounds.south = Math.min(bounds.south, lat); 
    bounds.east = Math.max(bounds.east, lng);
    bounds.west = Math.min(bounds.west, lng);
  });
  return bounds;
}