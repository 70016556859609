import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './Checkout.css'
import { GoogleMap, useLoadScript, Marker, Polygon, GroundOverlay, OverlayView } from '@react-google-maps/api';
import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Box, TextField, FormControlLabel, Radio, RadioGroup, CircularProgress, Skeleton, TextareaAutosize, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';    
import { CardElement, useStripe, useElements, } from '@stripe/react-stripe-js';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import { calculateAreaFromCoordinates } from '../../functions/calculateAreaFromCoordinates';
import { calculateCoordinatesFromPath } from '../../functions/calculateCoordinatesFromPath';
import { calculatePolygonArea } from '../../functions/calculatePolygonArea';
import { fetchFromAPI } from '../../functions/fetchFromAPI';
import { returnZipCodeLatLng } from '../../functions/zipCodes';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import WidgetsIcon from '@mui/icons-material/Widgets';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import googleMapsStyle from '../../assets/googleMapsStyle.json'
import usZipCodes from '../../assets/usZipCodes.json'
import { formatAsDollar } from '../../functions/formatAsDollar';
import AuthInterface from '../AuthInterface/AuthInterface';
import { ControlCamera } from '@mui/icons-material';


function Checkout(props) {

    // get lotId from url 
    const { lotId, zipCode } = useParams();

    const mode = props.mode
    const sharedStateObj = props.sharedStateObj

    const configOptions = {
        "createLot": {
            header: "Create Lot",
            headerIcon: <AddCircleIcon style={{marginRight: '10px'}}/>,
            marks: [
                {
                    value: 0,
                    label: 'Select',
                },                
                {
                    value: 1,
                    label: 'Choose Option',
                },
                {
                    value: 2,
                    label: 'Configure',
                    
                },
                {
                    value: 3,
                    label: 'Checkout',
                },                                    
            ],
            getOrgPaymentMethodsOnLoad: true
        },
        "addSubscription": {
            header: "Add Subscription",
            headerIcon: <AddCircleIcon style={{marginRight: '10px'}}/>,
            marks: [                
                {
                    value: 1,
                    label: 'Choose',
                },
                {
                    value: 3,
                    label: 'Checkout',
                },                                    
            ],
            getOrgPaymentMethodsOnLoad: true
        },
        "welcome":{
            "header": "Let's Get Started",
            "headerIcon": <AddCircleIcon style={{marginRight: '10px'}}/>,
            "marks": [
                {
                    value: 0,
                    label: 'Select',
                },                
                {
                    value: 1,
                    label: 'Choose',
                },
                {
                    value: 2,
                    label: 'Configure',
                    
                },
                {
                    value: 3,
                    label: 'Checkout',
                },                                    
            ],
            getOrgPaymentMethodsOnLoad: false
        }
    }

    const configObj = configOptions[mode]

    const orgObj = props.organizationObj
    const firestore = firebase.firestore();
    const theme = useTheme();
    const stripe = useStripe();
    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const [location, setLocation] = useState({lat: 28.5015, lng: -81.38541,})
    const currentLocation  = useRef({lat: 28.5015, lng: -81.38541});

    const pricingSchedule = props.generalAppConfig?.StripePricingSchedule ?? {}



    const [mapref, setMapRef] = React.useState(null);
    const [polygonBounds, setPolygonBounds] = React.useState([{lat: 0, lng: 0}]);
    const [polygonInstance, setPolygonInstance] = React.useState(null);
    const [polygonArea, setPolygonArea] = React.useState(0);

    const [tifSquares, setTifSquares] = React.useState([])

    const [lotDisplayName, setLotDisplayName] = React.useState("My Lot")
    const [lotDisplayNameTouched, setLotDisplayNameTouched] = React.useState(false)
    const lotDisplayNameMaxLength = 50
    const [lotDescription, setLotDescription] = React.useState("")
    const [lotDescriptionTouched, setLotDescriptionTouched] = React.useState(false)
    const lotDescriptionMaxLength = 100
    const [lotUseCase, setLotUseCase] = React.useState("")
    const [lotUseCaseTouched, setLotUseCaseTouched] = React.useState(false)
    const lotUseCaseMaxLength = 500
    const [selectedPricingPlan, setSelectedPricingPlan] = React.useState("monthlyBasemaps");

    const [zipCodeTextInput, setZipCodeTextInput] = useState(zipCode)
    const [userLot, setUserLot] = useState({})
    
    const [currentStep, setCurrentStep] = useState(Math.min(...configObj['marks'].map(e => e.value)))

    const [getPaymentMethodsLoading, setGetPaymentMethodsLoading] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState([])
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
    const [createLoading, setCreateLoading] = useState(false)

    const navigate = useNavigate()

    const rectangleOptions = {
        fillColor: "#000000",
        fillOpacity: .4,
        strokeColor: "#000000",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 1
    }

    const mapOptions = {
        styles: googleMapsStyle
    }

    const { isLoaded } = useLoadScript({    
        googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"
     })

    

     
    const handlePolygonDrag = () => {
        if (polygonInstance) {
            const area = calculatePolygonArea(polygonInstance);
            setPolygonArea(area);

            //set the polygon bounds
            const path = polygonInstance.getPath();
            const coordinates = calculateCoordinatesFromPath(path)
            setPolygonBounds(coordinates)
            sharedStateObj.sharedStateRef.current = {
                ...sharedStateObj.sharedStateRef.current,
                checkout_polygonBounds: coordinates
            }
        }
    }

    const handlePolygonLoad = (polygon) => {
        setPolygonInstance(polygon);
        const area = calculatePolygonArea(polygon);
    }
    

    const handleOnLoad = map => {
        setMapRef(map);
        handleCenterChanged(map);
    };

    useEffect(() => {

        //init shared state
        if(sharedStateObj.sharedStateRef.current.checkout_polygonBounds != null){
            const coordinates = sharedStateObj.sharedStateRef.current.checkout_polygonBounds
            setPolygonBounds(coordinates)            
            const area = calculateAreaFromCoordinates(coordinates);
            setPolygonArea(area);
        }
        if(sharedStateObj.sharedStateRef.current.checkout_selectedPricingPlan != null){
            setSelectedPricingPlan(sharedStateObj.sharedStateRef.current.checkout_selectedPricingPlan)
        }
        if(sharedStateObj.sharedStateRef.current.checkout_lotDisplayName != null){
            setLotDisplayName(sharedStateObj.sharedStateRef.current.checkout_lotDisplayName)
        }
        if(sharedStateObj.sharedStateRef.current.checkout_lotUseCase != null){
            setLotUseCase(sharedStateObj.sharedStateRef.current.checkout_lotUseCase)
        }
        if(sharedStateObj.sharedStateRef.current.checkout_currentStep != null){
            setCurrentStep(sharedStateObj.sharedStateRef.current.checkout_currentStep)
        }


    }, [])

    useEffect(() => {
        if(zipCode != null && returnZipCodeLatLng(zipCode) != null){
            navigateToZipCode(zipCode)

        }else{
            if (navigator.geolocation) {
                try{
                  navigator.geolocation.getCurrentPosition(function(resp){
                    var userLocationElement = {
                      lat: resp.coords.latitude,
                      lng: resp.coords.longitude
                    }
          
                    setLocation(userLocationElement)                      
                  });
                }catch(err){ 
                  console.error(err)
                }
              }
        }



        

        if(configObj.getOrgPaymentMethodsOnLoad){
            getOrgPaymentMethods()
        }


        

      }, []);

    useEffect(() => { 
        if(lotDisplayName != ""){
            setLotDisplayNameTouched(true)
            sharedStateObj.sharedStateRef.current = {
                ...sharedStateObj.sharedStateRef.current,
                checkout_lotDisplayName: lotDisplayName
            }
        }        
    }, [lotDisplayName])    
    useEffect(() => { 
        if(lotUseCase != ""){
            setLotUseCaseTouched(true)
            sharedStateObj.sharedStateRef.current = {
                ...sharedStateObj.sharedStateRef.current,
                checkout_lotUseCase: lotUseCase
            }
        }
    }, [lotUseCase])
    useEffect(() => {
        sharedStateObj.sharedStateRef.current = {
            ...sharedStateObj.sharedStateRef.current,
            checkout_selectedPricingPlan: selectedPricingPlan
        }
    }, [selectedPricingPlan])
    useEffect(() => {
        sharedStateObj.sharedStateRef.current = {
            ...sharedStateObj.sharedStateRef.current,
            checkout_currentStep: currentStep
        }
    }, [currentStep])

    useEffect(() => {         
        //calculateTifSquares()         
    }, [polygonBounds])

    function navigateToZipCode(_zipCode){ 
        if(_zipCode != null && returnZipCodeLatLng(_zipCode) != null){
            const zipCodeObj = returnZipCodeLatLng(_zipCode)
            setLocation({lat: zipCodeObj.lat, lng: zipCodeObj.lng})
        }
    }

    const handleCenterChanged = (_map) => {

        if (_map) {
          currentLocation.current = {lat:_map.center.lat(), lng: _map.center.lng()}
        }
    };

    const resetPolygonToEmpty = () => {

        setPolygonBounds([{lat: 0, lng: 0}])
        setPolygonArea(0)
    }

    const createPolygonAtCenter = () => {

        //get the current bounds of the map using mapref
        const bounds = mapref.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        const verticalDistance = Math.abs(ne.lat() - sw.lat());
        const horizontalDistance = Math.abs(ne.lng() - sw.lng());

        // create a polygon at the center of the map where the user is looking, make it reach half way from the center to the edge of the map in each direction
        // it will be an array of 4 points, each object will have lat and lng
        setPolygonBounds([
            { lat: currentLocation.current.lat - verticalDistance / 4, lng: currentLocation.current.lng - horizontalDistance / 4 },
            { lat: currentLocation.current.lat + verticalDistance / 4, lng: currentLocation.current.lng - horizontalDistance / 4 },
            { lat: currentLocation.current.lat + verticalDistance / 4, lng: currentLocation.current.lng + horizontalDistance / 4 },
            { lat: currentLocation.current.lat - verticalDistance / 4, lng: currentLocation.current.lng + horizontalDistance / 4 }
        ]);
        

        setTimeout(() => {
            handlePolygonDrag()            
        }, 100);
    }

    const calculateTifSquares = () => {

        try{
            //each square is .25 degrees by .25 degrees
            const squareSize = .25
            const bounds = mapref.getBounds();
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            //round down and up to the nearest .25
            const north = Math.ceil(ne.lat() / squareSize) * squareSize;
            const south = Math.floor(sw.lat() / squareSize) * squareSize;
            const east = Math.ceil(ne.lng() / squareSize) * squareSize;
            const west = Math.floor(sw.lng() / squareSize) * squareSize;
        
            const tempSquares = []

            for (let i = south; i < north; i += squareSize) {
                for (let j = west; j < east; j += squareSize) {
                    tempSquares.push([
                        { lat: i, lng: j },
                        { lat: i + squareSize, lng: j },
                        { lat: i + squareSize, lng: j + squareSize },
                        { lat: i, lng: j + squareSize }
                    ])
                }
            }

            setTifSquares(tempSquares)

        }catch(err){
            
        }

    }

    function convertSquareMetersToSquareKm(meters) {
        let squareKm = meters / 1000000;
        const area = Math.floor(squareKm * 100) / 100; // Round down to 2 decimal places
        return area.toFixed(2);
    }
    
    function validateSelectLot(){
        //validate lot is larger than 50 km²
        if(convertSquareMetersToSquareKm(polygonArea) < 50){
            return true
        }else if(convertSquareMetersToSquareKm(polygonArea) > 10000){
            return true
        }else{
            return false
        }
    }

    function validateLotDisplayName(){
        if(lotDisplayName.length < 3 || lotDisplayName.length > lotDisplayNameMaxLength){
            return true
        }else{
            return false
        }
    }

    function validateLotDescription(){
        if(lotDescription.length > lotDescriptionMaxLength){
            return true
        }else{
            return false
        }
    }

    function validateLotUseCase(){
        if(lotUseCase.length > lotUseCaseMaxLength){
            return true
        }else{
            return false
        }
    }

    function validateConfigure(){
        return validateLotDisplayName() || validateLotDescription() || validateLotUseCase()
    }

    function validateChooseOption(){
        if(!(selectedPricingPlan in pricingSchedule)){
            return true
        }else{
            return false
        }    
    }

    function validatePaymentMethod(){
        if(selectedPaymentMethod.length < 1 || selectedPaymentMethod == "" || selectedPaymentMethod == null){
            return true
        }else if(!paymentMethods.map((paymentMethod) => paymentMethod.id).includes(selectedPaymentMethod)){
            return true
        }else{
            return false
        }    
    }

    function validateStepDisabled(_step){

        switch(_step){
            case 0:
                return validateSelectLot()
            case 1:
                return validateSelectLot() || validateChooseOption()
            case 2:
                return validateSelectLot() || validateChooseOption() || validateConfigure()                    
            case 3:
                return validateSelectLot() || validateChooseOption() || validateConfigure() || validatePaymentMethod()
            default:
                return true
        }
    }

    function getOrgPaymentMethods() {

        setGetPaymentMethodsLoading(true)
        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
        }

        fetchFromAPI('stripe', "organization/wallet/listCards", {method: 'POST', body: fetchBody})
        .then((data) => {
            if(data?.length > 0){
                setPaymentMethods(data)

                setSelectedPaymentMethod(data.sort((a, b) => {
                    //sort by isDefaultPaymentMethod to be first
                    if(a?.isDefaultPaymentMethod){
                        return -1
                    }else if(b?.isDefaultPaymentMethod){
                        return 1
                    }else{
                        return 0
                    }                                                
                })[0].id)

            }else{
                setPaymentMethods([])
            }
        })
        .catch((error) => {
            console.error("Error getting payment methods: ", error);
            const message = error.message || "There was an error getting the payment methods"
            setPaymentMethods([])
            createAlert("error", message)
        })
        .finally(() => {
            setGetPaymentMethodsLoading(false)
        })
    }

    function submitCheckout() {
        setCreateLoading(true)
        const dataObj = {
            "organizationId": orgObj?.selectedOrganization,
            "priceId": pricingSchedule[selectedPricingPlan]['stripePriceId'],
            "priceIdOneTime": pricingSchedule[selectedPricingPlan]['stripePriceIdOneTime'],
            "paymentMethodId": selectedPaymentMethod,
            "polygon": polygonBounds,            
            "displayName": lotDisplayName,  
            "lotUseCase": lotUseCase                  
        }

        fetchFromAPI('stripe', "organization/subscription/create", {method: 'POST', body: dataObj})
        .then(async (data) => {
            
            const initialPaymentIntent = data?.initialPaymentIntent
            
            if(initialPaymentIntent.status == "success"){
                createAlert("success", "Lot created successfully")
                if(data.lotDocumentId){
                    navigate(`/Organization/Lots/${data.lotDocumentId}`)
               }
            }else if(initialPaymentIntent.status == "requires_confirmation" || initialPaymentIntent.status == "requires_action"){
                
                const { error } = await stripe.confirmCardPayment(initialPaymentIntent.client_secret);

                if(error){
                    console.error("Error confirming payment: ", error)
                    createAlert("error", "There was an error billing your payment method, please update your lot's billing details.")
                    if(data.lotDocumentId){
                        navigate(`/Organization/Lots/${data.lotDocumentId}/Subscription`)
                    }
                }else{
                    createAlert("success", "Lot created successfully")                    
                    if(data.lotDocumentId){
                        navigate(`/Organization/Lots/${data.lotDocumentId}`)
                    }
                }

                

                

            }else{
                createAlert("error", "There was an error creating the lot")
                setCreateLoading(false)
            }
            
        })
        .catch((error) => {
            console.error("Error creating lot: ", error);
            const message = error.message || "There was an error creating the lot"
            createAlert("error", message)
            setCreateLoading(false)
        })

    }
    
    if(!isLoaded) {
        return <div></div>
    }

    const polygonSet = JSON.stringify(polygonBounds) != JSON.stringify([{lat: 0, lng: 0}])

    return (
        <div className="checkoutContent">
            <div className='checkoutContentScroller'>
                <div className='checkoutContentHolder'>
                    <Paper elevation={2} className='checkoutContentPaper'>
                        <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                            {configObj['headerIcon']}
                            {configObj['header']}
                        </div>
                        <div className='checkoutInfoArea'>
                        
                        </div>
                    </Paper>
                    <Paper elevation={2} className='checkoutContentPaper' style={{display: currentStep == 0 ? 'flex':'none', flex: 100, }}>
                        <div className='checkoutContentPaperHeaderArea'>
                            <h2>Select Lot</h2>
                            <Button
                                variant="text"
                                disabled
                                color="primary"
                                style={{color: theme.palette.primary.main}}>
                                Min. lot size 50 km²
                            </Button>
                        </div>                        
                        <GoogleMap    
                            zoom={11} 
                            center={location} 
                            mapContainerClassName="checkoutMapContainer"
                            options={mapOptions}
                            onLoad={handleOnLoad}
                            onCenterChanged={() => { handleCenterChanged(mapref) }}>
                            <Polygon
                                editable={true}
                                draggable={true}
                                paths={polygonBounds}                
                                onLoad={handlePolygonLoad}
                                onMouseUp={handlePolygonDrag}
                                options={{
                                    ...rectangleOptions,
                                    fillColor: "#f9a825",
                                    strokeColor: "#f9a825",
                                    strokeWeight: 3,
                                }}/>
                            {
                                tifSquares.map((square) => {
                                    return (
                                        <Polygon
                                            paths={square}
                                            options={{
                                                ...rectangleOptions,
                                                fillColor: "#000000",
                                                fillOpacity: 0,
                                                strokeColor: "#000000",
                                                strokeOpacity: 1,
                                                strokeWeight: 1,
                                            }}/>
                                    )
                                })
                            }
                            </GoogleMap>           
                        <div className="checkoutMapButtonHolder">
                            <IconButton
                                onClick={() => { 
                                    navigateToZipCode(zipCodeTextInput)
                                 }}
                                disabled={returnZipCodeLatLng(zipCodeTextInput) == null}
                                style={{
                                    marginRight: '10px', 
                                    backgroundColor: returnZipCodeLatLng(zipCodeTextInput) == null ? theme.palette.gray.main:theme.palette.secondary.main, 
                                    color: returnZipCodeLatLng(zipCodeTextInput) == null ? theme.palette.gray.contrastText:theme.palette.secondary.contrastText,
                                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                                }}>
                                <ControlCamera />
                            </IconButton>
                            <TextField
                                label="Zip / Postal Code"
                                variant="outlined"
                                value={zipCodeTextInput}
                                onChange={(e) => { setZipCodeTextInput(e.target.value)}}
                                style={{marginRight: '10px', }}
                                size='small'/>
                            

                            <Button
                                variant="contained"
                                color={!polygonSet?"secondary":"primary"}
                                onClick={() => { !polygonSet ? createPolygonAtCenter():resetPolygonToEmpty() }}
                                startIcon={!polygonSet ? <AddCircleIcon />:<RestartAltIcon />}
                                style={{flex: 1,}}>
                                {
                                    !polygonSet ? "Select Area" : "Restart"
                                }
                            </Button>

                            <h3><span >Total Area:</span> <span style={{marginLeft: '10px', }}>{`${convertSquareMetersToSquareKm(polygonArea)} km²`}</span></h3>

                        </div>         
                    </Paper>                    
                    <Paper elevation={2} className='checkoutContentPaper' style={{display: currentStep == 1 ? 'flex':'none' }}>
                        <div className='checkoutContentPaperHeaderArea'>
                            <h2>Choose Option</h2>                            
                            {/* <Button
                                variant="text"
                                color="primary"
                                onClick={() => { 
                                    window.open('/pricing', '_blank');
                                }}
                                startIcon={<WidgetsIcon />}>
                                View Pricing
                            </Button> */}
                        </div>
                        <div className="checkoutOptionsArea">
                            <RadioGroup
                                defaultValue={"quarterly"}
                                value={selectedPricingPlan}
                                onChange={(event) => { 
                                    setSelectedPricingPlan(event.target.value)                                     
                                }}
                                >
                            <table>
                                <tr>
                                    <th >
                                        Options
                                    </th>
                                    <th >
                                        Price
                                    </th>
                                </tr>
                                {
                                    //order pricingSchedule by the key index and filter if displayOptions.checkout == true
                                    Object.keys(pricingSchedule).sort((a, b) => pricingSchedule[a].index - pricingSchedule[b].index).map((key, index) => {
                                        const element = pricingSchedule[key]

                                        const costPer = convertSquareMetersToSquareKm(polygonArea) * element?.price/100

                                        return (
                                            <tr 
                                                className='priceSelectorTr' 
                                                onClick={() => {
                                                    setSelectedPricingPlan(key)                                                    
                                                }}
                                                style={{backgroundColor: selectedPricingPlan == key ? '#f9a825':"#eeeeee"}}>
                                                <td className='priceSelector'>
                                                    <FormControlLabel 
                                                        value={key}
                                                        control={
                                                            <Radio  
                                                                color="primary"/>
                                                        } 
                                                        label={element?.name} />
                                                    <p>{`${formatAsDollar(element?.price/100)}/${element?.units} each ${element?.billingSchedule}`}</p>
                                                    <p>{element?.summary}</p>
                                                    
                                                </td>
                                                <td className='priceTd' >
                                                    <h3>{`${formatAsDollar(costPer)}/${element?.billingSchedule}`}</h3>
                                                    <p>Lot Size: {convertSquareMetersToSquareKm(polygonArea)} km²</p>
                                                </td>
                                            </tr>
                                        )
                                    })

                                }
                                
                            </table>
                            </RadioGroup>
                        </div>
                    </Paper>
                    <Paper elevation={2} className='checkoutContentPaper' style={{display: currentStep == 2 ? 'flex':'none' }}>
                        <div className='checkoutContentPaperHeaderArea'>
                            <h2>Configure</h2>
                        </div>
                        <TextField  
                            label="Display Name" 
                            helperText={`Required* (${lotDisplayName.length} of ${lotDisplayNameMaxLength} characters)`}                            
                            variant="outlined" 
                            value={lotDisplayName}
                            maxLength={lotDisplayNameMaxLength}
                            onChange={(e) => setLotDisplayName(e.target.value)}
                            error={validateLotDisplayName() && lotDisplayNameTouched}
                            style={{
                                marginBottom: '15px',
                            }}/>           
                        {/* <TextField
                            label="Description"
                            helperText="Optional: A short description of the lot"
                            variant="outlined"
                            value={lotDescription}
                            maxLength={lotDescriptionMaxLength}
                            onChange={(e) => { setLotDescription(e.target.value)}}
                            style={{
                                marginBottom: '10px',
                            }}/> */}
                        <TextField
                            label="Use Case"
                            helperText={`Optional: Help our team understand the analytics you are looking for (${lotUseCase.length} of ${lotUseCaseMaxLength} characters)`}
                            placeholder="Use Case"
                            value={lotUseCase}
                            maxLength={lotUseCaseMaxLength}
                            multiline={true}
                            error={validateLotUseCase() && lotUseCaseTouched}
                            onChange={(e) => setLotUseCase(e.target.value)}
                            style={{
                                
                            }}/>


                    </Paper>
                    <Paper elevation={2} className='checkoutContentPaper' style={{display: currentStep == 3 ? 'flex':'none', }}>

                        {
                            loggedIn ? (
                                orgObj?.selectedOrganization != null ? (
                                    <>
                                        <div className='checkoutContentPaperHeaderArea'>
                                            <h2>Select Payment Method</h2>                            
                                            <Button
                                                variant="text"
                                                color="primary"
                                                onClick={() => { 
                                                    window.open('/Organization/Billing', '_blank');
                                                }}
                                                startIcon={<CreditCardIcon />}>
                                                Manage
                                            </Button>
                                        </div>
                                        <p>{"This lot will be billed today for the current month's data, then on the 1st of each month moving forward."} </p>
                                        <div className="checkoutPaymentOptionsArea" style={{overflowX: createLoading ? 'hidden':'auto'}}>
                                            {
                                                createLoading ?
                                                    <Skeleton variant="rectangular" width={2000} height={297} />
                                                    :
                                                    <div 
                                                        className='checkoutPaymentOptionsAreaHolder'
                                                        style={{width: `${(paymentMethods.length + 1)*430}px`}}>
                                                        {
                                                            paymentMethods.sort((a, b) => {
                                                                //sort by isDefaultPaymentMethod to be first
                                                                if(a?.isDefaultPaymentMethod){
                                                                    return -1
                                                                }else if(b?.isDefaultPaymentMethod){
                                                                    return 1
                                                                }else{
                                                                    return 0
                                                                }                                                
                                                            }).map((paymentMethod) => {
                                                                return (
                                                                    <PaymentMethod 
                                                                        key={paymentMethod.id}
                                                                        selected={selectedPaymentMethod == paymentMethod.id} 
                                                                        loading={false} 
                                                                        create={false} 
                                                                        organizationId={orgObj?.selectedOrganization} 
                                                                        paymentMethodObj={paymentMethod} 
                                                                        createAlert={createAlert} 
                                                                        refreshWallet={getOrgPaymentMethods}
                                                                        manageOnClick={() => setSelectedPaymentMethod(paymentMethod.id)}/>
                                                                )
                                                            })
                                                        }
                                                        <PaymentMethod 
                                                            key={"create"}
                                                            selected={false}
                                                            loading={getPaymentMethodsLoading} 
                                                            create={true} 
                                                            organizationId={orgObj?.selectedOrganization} 
                                                            paymentMethodObj={{
                                                                userDefaultPaymentMethodSet: paymentMethods?.length > 0 ? paymentMethods[0]?.userDefaultPaymentMethodSet == true : false
                                                            }}
                                                            createAlert={createAlert} 
                                                            refreshWallet={getOrgPaymentMethods}/>
                                                    </div>
                                            }                            
                                        </div>
                                    </>):(<>
                                        <div className='checkoutContentPaperHeaderArea'>
                                            <h2>Building Organization Profile</h2>
                                                                                
                                        </div>
                                        <LinearProgress color='secondary'/>                 
                                    </>)
                            ):(<>
                                <div className='checkoutContentPaperHeaderArea'>
                                    <h2>Create an Account</h2>                                                                
                                </div>
                                <p>Manage your lots by signing in with OAuth or email.</p>
                                <AuthInterface auth={props.auth} user={props.user} createAlert={createAlert} loadPage={"welcomeCreateAccount"}/>
                            </>)
                        }

                        
                    </Paper>
                    
                    <div style={{flex: 1}}></div>
                    <div className='checkoutContentNavigationHolder'>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => { setCurrentStep(currentStep - 1) }}
                            disabled={currentStep == Math.min(...configObj['marks'].map(e => e.value)) || createLoading}
                            startIcon={<NavigateBeforeIcon />}
                            style={{width: '120px', opacity: currentStep == Math.min(...configObj['marks'].map(e => e.value)) ? 0:1}}>
                            Back
                        </Button>
                        <div style={{width: '30px'}}></div>
                        <Box sx={{ flex: 1 }}>
                            <Slider
                                aria-label=""
                                defaultValue={Math.min(...configObj['marks'].map(e => e.value))}                                
                                value={currentStep}
                                onChange={(event, newValue) => { setCurrentStep(newValue) } }
                                step={null}
                                min={Math.min(...configObj['marks'].map(e => e.value)) - .25}
                                max={Math.max(...configObj['marks'].map(e => e.value)) + .25}
                                color='secondary'
                                valueLabelDisplay={'off'}
                                disabled
                                sx={{                                    
                                    '& .MuiSlider-thumb.Mui-disabled': {
                                      bgcolor: theme.palette.secondary.main,
                                    },
                                  }}                          
                                marks={configObj['marks'].map(e => {
                                    if(e.value == 3){
                                        const label = orgObj?.selectedOrganization != null ? "Checkout": "Create Account"
                                        return {value: e.value, label: label}
                                    }else{
                                        return {value: e.value, label: e.label}
                                    }

                                })}
                            />
                        </Box>
                        <div style={{width: '30px'}}></div>
                        {
                            currentStep == 3 ? 
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => { submitCheckout() }}
                                disabled={validateStepDisabled(currentStep) || createLoading}
                                endIcon={createLoading ? <CircularProgress color="inherit" size={14}/>: <AddCircleIcon />}
                                style={{width: '120px'}}>
                                Create
                            </Button>
                            :
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => { setCurrentStep(currentStep + 1) }}
                                disabled={validateStepDisabled(currentStep)}
                                endIcon={<NavigateNextIcon />}
                                style={{width: '120px'}}>
                                Next
                            </Button>
                        }
                    </div>
                </div>
            </div>            
            <Footer></Footer>
        </div>
    )

}


export default Checkout