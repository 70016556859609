
export function calculateProcessingGridSquareBounds(_pixelId, _metaData, _squareBounds){
    const xCord = parseInt(_pixelId.split('-')[0])
    const yCord = parseInt(_pixelId.split('-')[1])


    const north = _metaData.squareLatLngs.lats[yCord]
    const south = _metaData.squareLatLngs.lats[yCord + _metaData.numberOfPixels]
    const west = _metaData.squareLatLngs.lngs[xCord]
    const east = _metaData.squareLatLngs.lngs[xCord + _metaData.numberOfPixels]

    return {
      north: yCord == 0 ? _squareBounds.north : north,
      south: south != undefined ? south : _squareBounds.south,
      west: xCord == 0 ? _squareBounds.west : west, 
      east: east != undefined ? east : _squareBounds.east
    }
  }