import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './AdminTasking.css'

import Footer from '../Footer/Footer'
import { Avatar, Box, Button, Divider, IconButton, LinearProgress, Paper, Tab, Tabs, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';


import { AddTask, HighlightOff, NotStarted, Refresh, Storage, ViewInAr, Visibility } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import * as turf from '@turf/turf';

import { invoiceStatuses } from '../StripeStatuses/StripeStatuses';

import { squareIdToLatLng } from '../../functions/squareIdToLatLng'
import { createSquare } from '../../functions/createSquare'
import { analyticsIdToDate } from '../../functions/analyticsIdToDate'
import { dateToLocaleUTCDateString } from '../../functions/dateToLocaleUTCDateString';
import { calculateSquareLotOverlap } from '../../functions/calculateSquareLotOverlap';
import { createAdminSquareFromId } from '../../functions/createAdminSquareFromId';
import DefaultAccount from '../../assets/DefaultAccount.png'


function AdminTasking(props) {

    const adminMode = props.adminMode
    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const adminHRObj = props?.adminHRObj
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const generalAppConfig = props.generalAppConfig

    const navigate = useNavigate()
    const theme = useTheme()

    const AOIDISTANCE = .25

    const [selectedTab, setSelectedTab] = useState(0)

    const [allTasks, setAllAnalyticsTasks] = useState({})

    const [tasksToReview, setTasksToReview] = useState([])
    const [reviewerRequestNewTaskDisabled, setReviewerRequestNewTaskDisabled] = useState(false)
    const [analysts, setAnalysts] = useState({})
    const taskActionsDisabledRef = useRef({})
    const [taskActionsDisabled, setTaskActionsDisabled] = useState({})

    const [allImageryTasks, setAllImageryTasks] = useState({})

    const disabledBackendTasksRef = useRef({})
    const [disabledBackendTasks, setDisabledBackendTasks] = useState({})

    useEffect(() => {
        listenForAnalyticsTasks()
        getAnalysts()
        listenForImageryTasks();

    }, [])

    function changeTabs(newValue){

        setSelectedTab(newValue)
    }

    function listenForAnalyticsTasks() {
        const analyticsTasksRef = firebase.firestore().collection("Operations").doc("LotOperations").collection("AnalyticRequests").where("manualProcessingObj.manualProcessingComplete", "==", false)

        analyticsTasksRef.onSnapshot((snapshot) => {

            const tasks = {}
            snapshot.forEach((doc) => {
                tasks[doc.id] = doc.data()
                tasks[doc.id].key = doc.id
            })

            setAllAnalyticsTasks(tasks)
        })
    }

    function listenForImageryTasks() {
        const imageryTasksRef = firebase.firestore().collection("Operations").doc("LotOperations").collection("ImageryRequests").where("processingComplete", "==", false)
        imageryTasksRef.onSnapshot((snapshot) => {
            var tasks = {}
            snapshot.forEach((doc) => {
                console.log(doc.data())
                tasks[doc.id] = doc.data()
                tasks[doc.id].id = doc.id
            })

            setAllImageryTasks(tasks)
        });
            
    }

    function getAnalysts(){
        const analystsRef = firebase.firestore().collection("Admin").doc("InternalHR").collection("Users")
        analystsRef.get().then((snapshot) => {
            const analysts = {}
            snapshot.forEach((doc) => {
                analysts[doc.id] = doc.data()
            })
            setAnalysts(analysts)
        })

    }

    function updateReviewerInfo(_taskKey, _task, _square, _active){

        taskActionsDisabledRef.current[_taskKey] = true
        setTaskActionsDisabled(taskActionsDisabledRef.current)

        const taskRef = firebase.firestore().collection("Operations").doc("LotActions").collection("AnalyticRequestActions")

        const updateObject = {
            userId: auth.currentUser.uid,
            action: "updateReviewerInfo",
            requestId: _task.key,
            squareId: _square.squareId.replaceAll(".",""),
            payload: {
                reviewer: {
                    uid: auth.currentUser.uid,
                    displayName: analysts[auth.currentUser.uid]?.displayName || "",
                    photoURL: analysts[auth.currentUser.uid]?.photoURL || ""
                },                             
                active: _active,                  
            },
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        }

        taskRef.add(updateObject)
        .then(() => {
            if(_active){
                createAlert("success", "Task started")
            }else{
                createAlert("success", "Task quit")
            }
        })
        .catch((error) => {
            createAlert("error", "Error starting task")
            console.error("Error writing document: ", error);
        })
        .finally(() => {
            setTimeout(() => {
                taskActionsDisabledRef.current[_taskKey] = false
                setTaskActionsDisabled(taskActionsDisabledRef.current)
            }, 2000);   
        })
        

    }

    function renderTaskCard(_task, _square, _cardType, _reviewerId){

        if(_square?.manualProcessingComplete == true){
            return null
        }else{
            try{
                var totalArea = 0
                const squareId = _square?.squareId?.replaceAll(".","")
                const squareElement = createAdminSquareFromId(squareId)?.polygon
                const lotPolygon = _task?.polygon

                const overlapPolygon = calculateSquareLotOverlap(squareElement, lotPolygon)
                if(overlapPolygon != null){
                    //get area of turf overlapPolygon
                    try{
                        const polygonAreaMetersSquared = turf.area(overlapPolygon)
                        //convert to km²
                        const polygonAreaKmSquared = polygonAreaMetersSquared / 1000000
                        totalArea = polygonAreaKmSquared
                        totalArea = Math.floor(polygonAreaKmSquared * 100) / 100

                    }catch(e){                                                
                        console.error(e)    
                    }
                }                                        

                const numberOfTotalSquares = _task?.requiredSquares?.length
                const numberAssigned = Object.values(_task?.manualProcessingObj?.squareStatus)?.filter((square) => Object.keys(square.reviewers).length > 0).length 
                const numberCompleted = Object.values(_task?.manualProcessingObj?.squareStatus)?.filter((square) => square?.manualProcessingComplete == true).length 

                const percentageAssigned = (numberAssigned / numberOfTotalSquares) * 100
                const percentageCompleted = (numberCompleted / numberOfTotalSquares) * 100

                const taskDate = analyticsIdToDate(_task?.dateRangeId)
                const dateString = dateToLocaleUTCDateString(taskDate, "en-US", {year: 'numeric', month: 'long',})

                var reviewers = _square?.reviewers

                const userReviewing = reviewers?.[auth.currentUser.uid]?.active == true

                const indexOfKey = _task?.requiredSquares?.indexOf(_square?.squareId)
                const taskKey = `${_task?.key}-${indexOfKey}`

                const submitForReview = _task?.manualProcessingObj?.squareStatus?.[squareId]?.reviewers?.[_reviewerId]?.submitForReview
                var latestSubmitForReviewObject = null
                try{
                    if(submitForReview != null){
                        latestSubmitForReviewObject = Object.values(submitForReview).sort((a, b) => a.submitKey - b.submitKey).pop()
                    }
                }catch(e){
                    console.error(e)
                }


                var actionButton = null
                if(_cardType == "myTasks"){

                    if(latestSubmitForReviewObject?.type == "submitChangesForReview"){
                        actionButton = <div className='submitChangesForReviewButtonHolder'>
                                            <IconButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    navigate(`/MapTasking/${squareId}/${_task?.key}`)
                                                }}                                                
                                                disabled={taskActionsDisabled[taskKey] == true}
                                                style={{
                                                    height: '40px',
                                                    width: '40px',
                                                    backgroundColor: theme.palette.secondary.main,
                                                }}>    
                                                <ViewInAr />                                            
                                            </IconButton>
                                            <Button 
                                                variant='outlined'
                                                color="primary"
                                                disabled={true}
                                                style={{marginLeft: '10px',  flex: 1}}>
                                                    In Review
                                            </Button>
                                        </div>
                    }else{
                        actionButton =  <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                navigate(`/MapTasking/${squareId}/${_task?.key}`)
                                            }}
                                            startIcon={<ViewInAr />}
                                            disabled={taskActionsDisabled[taskKey] == true}
                                            style={{
                                                marginTop: '10px',
                                                width: '100%',
                                            }}>
                                            Label
                                        </Button>
                    }
                    
                }else if(_cardType == "openTasks"){
                    if(latestSubmitForReviewObject?.type == "submitChangesForReview"){
                        actionButton = <div className='submitChangesForReviewButtonHolder'>                                            
                                            <Button 
                                                variant='outlined'
                                                color="primary"
                                                disabled={true}
                                                style={{marginLeft: '10px', flex: 1}}>
                                                    In Review
                                            </Button>
                                        </div>
                    }else{
                        actionButton = <Button
                                            variant="contained"
                                            color={userReviewing? "gray":"primary"}
                                            onClick={() => {
                                                updateReviewerInfo(taskKey, _task, _square, !userReviewing)
                                            }}
                                            startIcon={userReviewing ? <HighlightOff />:<NotStarted />}
                                            disabled={taskActionsDisabled[taskKey] == true}
                                            style={{
                                                marginTop: '10px',
                                                width: '100%',
                                            }}>
                                            {userReviewing ? 'Quit':'Start'}
                                        </Button>
                    }
                }else if(_cardType == "submittedForReview"){
                    reviewers = {
                        [_reviewerId]: reviewers[_reviewerId]
                    }
                    actionButton =  <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            navigate(`/MapValidation/${squareId}/${_task?.key}/${_reviewerId}`)
                                        }}
                                        startIcon={<Visibility />}
                                        disabled={taskActionsDisabled[taskKey] == true}
                                        style={{
                                            marginTop: '10px',
                                            width: '100%',
                                        }}>
                                        Review
                                    </Button>
                }


                const filteredReviewers = Object.keys(reviewers).filter((reviewerKey) => reviewers[reviewerKey]?.active == true)

                return (
                    <Paper elevation={3} className='adminTaskingContentTask' key={`${_cardType}-${taskKey}-${_reviewerId}`}>
                        <h3>{`${(indexOfKey + 1).toString().padStart(3, "0")} - ${_task?.displayName}`}</h3>                                            
                        <Box sx={{ width: '100%', paddingTop: '5px', paddingBottom: '15px' }}>
                            <LinearProgress  variant="buffer" color="secondary" value={percentageCompleted} valueBuffer={percentageAssigned}  />
                        </Box>
                        <div className='adminTaskingContentTaskData'>
                            <h5>Date:</h5>
                            <p>{dateString}</p>
                        </div>
                        <div className='adminTaskingContentTaskData'>
                            <h5>Total Area:</h5>
                            <p>{`${totalArea} km²`}</p>
                        </div>
                        <div className='adminTaskingContentTaskData'>
                            <h5>Square Id:</h5>
                            <p>{_square?.squareId}</p>
                        </div>
                        <div className='adminTaskingContentTaskData' style={{flex: 1, flexDirection: 'column'}}>
                            <h5>{filteredReviewers.length == 0 ? 'No Analysts:': filteredReviewers.length > 1 ? 'Analysts:':`Analyst:`}</h5>
                            <div className='adminTaskingContentTaskDataAnalystHolder'>
                                {
                                    filteredReviewers.map((reviewerKey) => {
                                        const reviewer = analysts[reviewerKey]
                                        if(reviewer != null){
                                            return (
                                                <Tooltip title={reviewer?.displayName} style={{marginLeft: '5px', marginBottom: '5px'}} key={`${taskKey}-${reviewerKey}`}>
                                                    <Avatar alt={reviewer?.displayName} src={reviewer?.photoURL} />
                                                </Tooltip>
                                            )
                                        }
                                    })  
                                }
                            </div>
                        </div>
                        {actionButton}
                    </Paper>
                )
            }catch(e){
                console.error(e)
                return <div>Error</div>
            }
        }
        
    }

    function submitDataRequestAction(_productType, _taskId){

        disabledBackendTasksRef.current[_taskId] = true
        setDisabledBackendTasks(disabledBackendTasksRef.current)

        if(_productType == "monthlyBasemaps"){
            //add a lot action
            const actionRef = firebase.firestore().collection("Operations").doc("LotActions").collection("ImageryRequestActions")
            const actionObject = {
                userId: auth.currentUser.uid,
                action: "process",
                imageryRequestId: _taskId,
                dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
            }
            console.log(actionObject)
            actionRef.add(actionObject)
            .then(() => {
                createAlert("success", "Task submitted")
            })
            .catch((error) => {
                createAlert("error", error.message)
                console.error("Error writing document: ", error);
            })
        }

        console.log(generalAppConfig, _taskId)

    }

    const tasksAwaitingReview = adminHRObj?.mapReviewManagerMode || adminMode ? 
                                    Object.keys(allTasks)
                                    .sort((a, b) => {
                                        return allTasks[a]?.dateRangeId - allTasks[b]?.dateRangeId
                                    })
                                    .map((key) => {
                                        const task = allTasks?.[key]
                                        const taskSquares = task?.manualProcessingObj?.squareStatus
                                        if(task != null && taskSquares != null){                                    
                                            //sort first by dateRangeId, Object.keys(reviewers).filter((reviewerKey) => reviewers[reviewerKey]?.active == true).length, then by squareId,
                                            return Object.values(taskSquares)
                                            .filter((square) => square?.manualProcessingComplete != true)
                                            .sort((a, b) => {
                                                // First level sort: by dateRangeId (assumes dateRangeId is comparable, e.g., a number or string)
                                                    if (a.dateRangeId < b.dateRangeId) return -1;
                                                    if (a.dateRangeId > b.dateRangeId) return 1;

                                                    // Third level sort: by squareId (assumes squareId is comparable, e.g., a number or string)
                                                    if (a.squareId < b.squareId) return -1;
                                                    if (a.squareId > b.squareId) return 1;

                                                    // If all criteria are equal, return 0
                                                    return 0;
                                            })
                                            .map((square, index) => {                                        
                                                try{
                                                    if(square?.reviewers != null){
                                                        console.log(square)
                                                        return Object.keys(square?.reviewers)
                                                                    .filter((reviewerKey) => square?.reviewers?.[reviewerKey]?.active == true)
                                                                    .map((reviewerKey) => {
                                                                        const reviewerObj = square?.reviewers[reviewerKey]
                                                                        if(reviewerObj?.submitForReview != null){
                                                                            const latestSubmitForReviewObject = Object.values(reviewerObj?.submitForReview).sort((a, b) => a.submitKey - b.submitKey).pop()
                                                                            if(latestSubmitForReviewObject?.type == "submitChangesForReview"){
                                                                                return {
                                                                                    "task": task, 
                                                                                    "square": square, 
                                                                                    "type": "submittedForReview", 
                                                                                    "reviewerKey": reviewerKey
                                                                                }
                                                                            }
                                                                        }else{
                                                                            return []
                                                                        }
                                                                    })
                                                    }else{
                                                        return []
                                                    }
                                                }catch(e){
                                                    console.error(e)
                                                    return []
                                                }
                                            })
                                        }else{
                                            return []
                                        }
                                    }).flat(Infinity):[]

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    const myTasks = Object.keys(allTasks)
                        .sort((a, b) => {
                            return allTasks[a]?.dateRangeId - allTasks[b]?.dateRangeId
                        }).map((key) => {
                            const task = allTasks?.[key]

                            const taskSquares = task?.manualProcessingObj?.squareStatus

                            if(task != null && taskSquares != null){
                                
                                //sort first by dateRangeId, Object.keys(reviewers).filter((reviewerKey) => reviewers[reviewerKey]?.active == true).length, then by squareId,
                                return Object.values(taskSquares)
                                .filter((square) => square?.reviewers?.[auth.currentUser.uid]?.active == true && square?.manualProcessingComplete != true)
                                .sort((a, b) => {
                                    // First level sort: by dateRangeId (assumes dateRangeId is comparable, e.g., a number or string)
                                        if (a.dateRangeId < b.dateRangeId) return -1;
                                        if (a.dateRangeId > b.dateRangeId) return 1;

                                        // Third level sort: by squareId (assumes squareId is comparable, e.g., a number or string)
                                        if (a.squareId < b.squareId) return -1;
                                        if (a.squareId > b.squareId) return 1;

                                        // If all criteria are equal, return 0
                                        return 0;
                                })
                                .map((square, index) => {
                                    return {
                                        "task": task, 
                                        "square": square, 
                                        "type": "myTasks", 
                                        "reviewerKey": auth.currentUser.uid
                                    }                                    
                                })
                            }
                        }).flat(Infinity)

    const IncompleteTasks = Object.keys(allTasks)
                                .sort((a, b) => {
                                    return allTasks[a]?.dateRangeId - allTasks[b]?.dateRangeId
                                }).map((key) => {
                                    const task = allTasks?.[key]

                                    const taskSquares = task?.manualProcessingObj?.squareStatus

                                    if(task != null && taskSquares != null){
                                        
                                        //sort first by dateRangeId, Object.keys(reviewers).filter((reviewerKey) => reviewers[reviewerKey]?.active == true).length, then by squareId,
                                        return Object.values(taskSquares)
                                        .filter((square) => square?.manualProcessingComplete != true)
                                        .sort((a, b) => {
                                            // First level sort: by dateRangeId (assumes dateRangeId is comparable, e.g., a number or string)
                                                if (a.dateRangeId < b.dateRangeId) return -1;
                                                if (a.dateRangeId > b.dateRangeId) return 1;

                                                // Second level sort: by number of active reviewers
                                                const activeReviewersA = Object.keys(a.reviewers).filter(
                                                    (reviewerKey) => a.reviewers[reviewerKey]?.active === true
                                                ).length;
                                                
                                                const activeReviewersB = Object.keys(b.reviewers).filter(
                                                    (reviewerKey) => b.reviewers[reviewerKey]?.active === true
                                                ).length;

                                                if (activeReviewersA < activeReviewersB) return -1;
                                                if (activeReviewersA > activeReviewersB) return 1;

                                                // Third level sort: by squareId (assumes squareId is comparable, e.g., a number or string)
                                                if (a.squareId < b.squareId) return -1;
                                                if (a.squareId > b.squareId) return 1;

                                                // If all criteria are equal, return 0
                                                return 0;

                                        })
                                        .map((square, index) => {
                                            return {
                                                "task": task, 
                                                "square": square, 
                                                "type": "openTasks", 
                                                "reviewerKey": auth.currentUser.uid
                                            }                                                                                            
                                        })
                                    }
                                }).flat(Infinity)
    
    const incompleteAnalyticRequests = Object.keys(allTasks)
                                            .sort((a, b) => {
                                                return allTasks[a]?.userData?.quarterDateVal - allTasks[b]?.userData?.quarterDateVal
                                            }).map((key) => {
                                                const task = allTasks?.[key]
                                                if(task != null){

                                                    const numberOfTotalSquares = task?.requiredSquares?.length
                                                    const numberAssigned = Object.values(task?.manualProcessingObj?.squareStatus)?.filter((square) => Object.keys(square.reviewers).length > 0).length 
                                                    const numberCompleted = Object.values(task?.manualProcessingObj?.squareStatus)?.filter((square) => square?.manualProcessingComplete == true).length 

                                                    const percentageAssigned = (numberAssigned / numberOfTotalSquares) * 100
                                                    const percentageCompleted = (numberCompleted / numberOfTotalSquares) * 100

                                                    const taskDate = analyticsIdToDate(task?.dateRangeId)
                                                    const dateString = dateToLocaleUTCDateString(taskDate, "en-US", {year: 'numeric', month: 'long',})

                                                    //ensure each reviewer is only counted once
                                                    var allActiveReviewers = Object.values(task?.manualProcessingObj?.squareStatus)?.map((square) => {
                                                        return Object.keys(square?.reviewers).filter((reviewerKey) => square?.reviewers[reviewerKey]?.active == true)
                                                    }).flat(Infinity).filter((value, index, self) => self.indexOf(value) === index)
                                                    try{
                                                        if(allActiveReviewers.length == 0){
                                                            allActiveReviewers = []
                                                        }
                                                    }catch(e){
                                                        console.error(e)
                                                        allActiveReviewers = []
                                                    }


                                                    return {
                                                        "key": key,
                                                        "task": task,
                                                        "percentageAssigned": percentageAssigned,
                                                        "percentageCompleted": percentageCompleted,
                                                        "dateString": dateString,
                                                        "numberOfTotalSquares": numberOfTotalSquares,
                                                        "numberAssigned": numberAssigned,
                                                        "numberCompleted": numberCompleted,
                                                        "allActiveReviewers": allActiveReviewers

                                                    }
                                                }
                                            }).flat(Infinity)



    return (
        <div className="adminTaskingContent">
            <div className='adminTaskingContentScroller'>
                <Paper elevation={2} className='adminTaskingContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <AddTask style={{marginRight: '10px'}}/>
                        Tasking
                    </div>
                    <Tabs 
                        value={selectedTab} 
                        onChange={(event, newValue) => changeTabs(newValue)} 
                        aria-label="basic tabs example"
                        style={{
                            display: 'flex',
                        }}>
                        <Tab 
                            label="Analyts Tasking" 
                            icon={<AddTask />} 
                            iconPosition="start"  
                            {...a11yProps(0)} />
                        <Tab 
                            label="Backend Processing" 
                            icon={<Storage />} 
                            iconPosition="start"  
                            {...a11yProps(1)}/>
                    </Tabs>
                    <Divider 
                        style={{
                            display: 'flex',
                        }}/>
                    <div className='adminTaskingContentArea' style={{display: selectedTab == 0 ? 'block':'none'}}>
                        <div className='adminTaskingContentArea'>
                            <div className='adminTaskingContentHeader' style={{display: adminHRObj?.mapReviewManagerMode ? 'flex':'none'}}>
                                <h3>{`Tasks Awaiting Review (${tasksAwaitingReview.length})`}</h3> 
                            </div>
                            <div className='adminTaskingContentTaskArea' style={{display: adminHRObj?.mapReviewManagerMode ? 'flex':'none'}}>
                            {
                                tasksAwaitingReview.map((task) => {
                                    try{
                                        return renderTaskCard(task.task, task.square, task.type, task.reviewerKey)
                                    }catch(e){
                                        console.error(e)
                                        return null
                                    }
                                })
                            }
                            </div>
                            <div className='adminTaskingContentHeader'>
                                <h3>{`My Tasks (${myTasks.length})`}</h3> 
                            </div>
                            <div className='adminTaskingContentTaskArea'>
                            {
                                myTasks.map((task) => {
                                    try{
                                        return renderTaskCard(task.task, task.square, task.type, task.reviewerKey)
                                    }catch(e){
                                        console.error(e)
                                        return null
                                    }
                                })
                            }
                            </div>
                            <div className='adminTaskingContentHeader'>
                                <h3>{`Incomplete Tasks (${IncompleteTasks.length})`}</h3> 
                            </div>
                            <div className='adminTaskingContentTaskArea'>
                            {
                                IncompleteTasks.map((task) => {
                                    try{
                                        return renderTaskCard(task.task, task.square, task.type, task.reviewerKey)
                                    }catch(e){
                                        console.error(e)
                                        return null
                                    }
                                })
                            }
                            </div>
                        </div>
                        <div className='adminTaskingContentArea'>
                            <div className='adminTaskingContentHeader'>
                                <h3>{`Incomplete Analytic Requests (${incompleteAnalyticRequests.length})`}</h3> 
                            </div>
                            <div className='adminTaskingContentTaskArea'>
                            {
                                incompleteAnalyticRequests.map((analytic) => {
                                    return (
                                        <Paper elevation={3} className='adminTaskingContentTask' key={`analytic-${analytic?.key}`}>
                                            <h3>{analytic?.task?.displayName}</h3>                                            
                                            <Box sx={{ width: '100%', paddingTop: '5px', paddingBottom: '15px' }}>
                                                <LinearProgress  variant="buffer" color="secondary" value={analytic?.percentageCompleted} valueBuffer={analytic?.percentageAssigned}  />
                                            </Box>
                                            <div className='adminTaskingContentTaskData'>
                                                <h5>Date:</h5>
                                                <p>{analytic?.dateString}</p>
                                            </div>
                                            <div className='adminTaskingContentTaskData'>
                                                <h5>Total Area:</h5>
                                                <p>{`${analytic?.task?.totalArea} km²`}</p>
                                            </div>
                                            <div className='adminTaskingContentTaskData'>
                                                <h5>Assigned Squares:</h5>
                                                <p>{`${analytic?.numberAssigned} of ${analytic?.numberOfTotalSquares}`}</p>
                                            </div>
                                            <div className='adminTaskingContentTaskData'>
                                                <h5>Completed Squares:</h5>
                                                <p>{`${analytic?.numberCompleted} of ${analytic?.numberOfTotalSquares}`}</p>
                                            </div>
                                            <div className='adminTaskingContentTaskData' style={{flex: 1, flexDirection: 'column'}}>
                                                <h5>{analytic?.allActiveReviewers.length == 0 ? 'No Analysts:': analytic?.allActiveReviewers.length > 1 ? 'Analysts:':`Analyst:`}</h5>
                                                <div className='adminTaskingContentTaskDataAnalystHolder'>
                                                    {
                                                        analytic?.allActiveReviewers?.map((reviewerKey) => {
                                                            const reviewer = analysts[reviewerKey]
                                                            if(reviewer != null){
                                                                return (
                                                                    <Tooltip title={reviewer?.displayName} style={{marginLeft: '5px', marginBottom: '5px'}} key={`analyticReviewer-${analytic?.key}-${reviewerKey}`}>
                                                                        <Avatar alt={reviewer?.displayName} src={reviewer?.photoURL} />
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        })  
                                                    }
                                                </div>
                                            </div>
                                        </Paper>
                                    )
                                })

                                        
                                
                            }
                            </div>
                        </div>
                    </div>
                    <div className='adminTaskingContentArea' style={{display: selectedTab == 1 ? 'block':'none'}}>
                        <TableContainer component={Paper}>
                            <Table style={{width: '100%'}} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell >Request Type</TableCell>
                                    <TableCell >Display Name</TableCell>
                                    <TableCell >Org/Lot Id</TableCell>
                                    <TableCell >Invoice Status</TableCell>                                    
                                    <TableCell >Created</TableCell>
                                    <TableCell >Actions</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {Object.values(allImageryTasks ?? {}).map((task) => (
                                    <TableRow
                                    key={task?.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >                                    
                                        <TableCell >{generalAppConfig?.StripePricingSchedule?.[task?.productType]?.name ?? task.productType}</TableCell>
                                        <TableCell >{task?.displayName}</TableCell>
                                        <TableCell >{task?.organizationId} <br /> {task?.lotId}</TableCell>
                                        <TableCell >{invoiceStatuses[task?.invoiceStatus] ?? task?.invoiceStatus}</TableCell>
                                        <TableCell >{(new Date(task?.dateCreated?.seconds * 1000)).toLocaleString("en-us", { dateStyle: 'medium', timeStyle: 'short'})}</TableCell>
                                        <TableCell >
                                            <Button 
                                                variant='contained'
                                                color='primary'
                                                startIcon={<Refresh />}
                                                size='small'
                                                disabled={disabledBackendTasks[task.id] == true}
                                                onClick={() => {
                                                    submitDataRequestAction(task.productType, task.id)
                                                }}>
                                                    re-run
                                            </Button>

                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Paper>
                
            </div>            
            <Footer></Footer>
            
        </div>
    )

}


export default AdminTasking