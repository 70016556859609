import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Chip, Drawer, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button, Divider, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import AuthInterface from '../AuthInterface/AuthInterface';
import firebase from 'firebase/compat/app'


import { AccountCircle, AddCircle, AddTask, Dashboard, Business, Home, Layers, Mail, Map, Menu, People, ViewInAr } from '@mui/icons-material';

import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/Logo_f.svg'
import logoWithTagline from '../../assets/Logo_withtagline.svg'

import { primaryTheme } from '../../theme'


import './Navbar.css'

function Navbar(props) {

    const createAlert = props.createAlert
    const organizationObj = props.organizationObj;
    const adminHRObj = props.adminHRObj;
    const currentUser = props.auth.currentUser

    const userIsOrgSuperAdmin = organizationObj?.userIsOrgSuperAdmin == true;

    const adminMode = props.adminMode;
    const mapTaskingMode = props?.adminHRObj?.mapTaskingMode == true;
    const mapReviewManagerMode = props?.adminHRObj?.mapReviewManagerMode == true;


    const [drawerOpen, setDrawerOpen] = React.useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        
    }, [props.organization])

    const toggleDrawer = (open, event) => {
        if (event.type === 'keydown' && ((event).key === 'Tab' || (event).key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    };

    const loggedIn = props.auth.currentUser != null
    const organizationSet = organizationObj?.organizationSet == true
    const organizationHasLots = typeof organizationObj?.orgObj?.stripeSubscription == 'object' ? Object.keys(organizationObj?.orgObj?.stripeSubscription).length > 0:false

    const menuItems = [
        
        {
            icon: <Dashboard />,
            to: '/AdminDashboard',
            text: 'Dashboard',
            noLogin: false,
            section: "admin",
            showAdminView: adminMode,
            key: "mapReview"
        },
        {
            icon: <Map />,
            to: '/MapReview',
            text: 'Map Review',
            noLogin: false,
            section: "admin",
            showAdminView: adminMode,
            key: "mapReview"
        },
        {
            icon: <People />,
            to: '/TaskingHR',
            text: 'Tasking HR',
            noLogin: false,
            section: "mapReview",
            showAdminView: mapTaskingMode || adminMode,
            key: "adminHR"
        },
        {
            icon: <AddTask />,
            to: '/Tasking',
            text: 'Tasking',
            noLogin: false,
            section: "mapReview",
            showAdminView: mapTaskingMode || adminMode,
            key: "tasking"
        },
        {
            icon: <ViewInAr />,
            to: '/Organization/MapView',
            text: 'Map View',
            noLogin: false,
            section: "console",
            showAdminView: (loggedIn && organizationSet && organizationHasLots) || adminMode,
            key: "mapView"
        },
        {
            icon: <Layers />,
            to: '/Organization/Lots',
            text: 'All Lots',
            noLogin: false,
            section: "console",
            showAdminView: (loggedIn && organizationSet && organizationHasLots) || adminMode,
            key: "allLots"
        },
        {
            icon: <AddCircle />,
            to: '/Organization/CreateLot',
            text: 'Create Lot',
            noLogin: false,
            section: "console",
            showAdminView: (((loggedIn && organizationSet && userIsOrgSuperAdmin) || adminMode)),
            key: "createLot"
        },
        
        {
            icon: <Business />,
            to: '/Organization',
            text: 'Organization',
            noLogin: false,
            section: "console",
            showAdminView: (loggedIn && organizationSet) || adminMode,
            key: "organization"
        },   
        {
            icon: <AccountCircle />,
            to: '/Account',
            text: 'Account',
            noLogin: false,
            section: "console",
            showAdminView: loggedIn,
            key: "account"
        },
        {
            icon: <Home />,
            to: '/',
            text: 'Home',
            noLogin: true,
            section: "general",
            showAdminView: false,
            key: "home"
        },        
        {
            icon: <Mail />,
            to: '/ContactUs',
            text: 'Contact Us',
            noLogin: true,
            section: "general",
            showAdminView: false,
            key: "contactUs"
        },
      

    ]
    
    var consoleButton

    if((loggedIn && organizationSet) || adminMode){
        consoleButton = <>
                            <Link to='/Organization/MapView' style={{marginRight: '20px'}}>
                                <Button 
                                    variant="text" 
                                    color="secondary" 
                                    startIcon={<Map />}>
                                    Map View
                                </Button>
                            </Link>
                            <AuthInterface auth={props.auth} user={props.user} createAlert={createAlert}/>
                        </>
        
    }else{
        consoleButton = <>                            
                            <AuthInterface auth={props.auth} user={props.user} createAlert={createAlert}/>
                        </>
    }

    var organizationKeys = organizationObj?.userOrganizations != undefined ? Object.keys(organizationObj.userOrganizations):[]
    var organizationSelect = null
    if(organizationObj?.selectedOrganization != null && organizationKeys.length > 0 && loggedIn){
        organizationSelect = <FormControl size="small" className="navbarOrganizationPicker" >
                                <InputLabel 
                                    id="demo-simple-select-label"
                                    color="white"
                                    size="small">Organization</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={organizationObj?.selectedOrganization}
                                    label="Organization"                            
                                    onChange={(event) => {
                                        organizationObj?.setSelectedOrganization(event.target.value)
                                    }}
                                    color="secondary"
                                    style={{ color: 'white', }}>
                                    {
                                        organizationKeys.map((key) => {
                                            const displayName = organizationObj.userOrganizations[key].displayName
                                            return <MenuItem value={key} key={key}>{displayName}</MenuItem>
                                        })
                                    }                      
                                </Select>
                                                      
                            </FormControl>   
        
                                
    }

    return (
        <>
            <AppBar position="static" className="appBar">
                <Toolbar>
                    <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    style={{ mr: 2 }}
                    onClick={(event) => {toggleDrawer(true, event)}}
                    >
                        <Menu />
                    </IconButton>
                    <a onClick={() => navigate("/")} className='hidden-mobile'>
                        <img src={logoWithTagline} alt="Logo" className="appBarHeaderLogo"/>                   
                    </a>
                    {
                        organizationSelect
                    }  
                    <div style={{flex: 1}}/>                    
                    {consoleButton}                    
                    
                    
                </Toolbar>
            </AppBar>
            <Drawer
                anchor={'left'}
                open={drawerOpen}
                onClose={(event) => {toggleDrawer(false, event)}}
                >
                 <Box
                    style={{ width: 270 }}
                    role="presentation"
                    onClick={() => toggleDrawer(false, {})}
                    onKeyDown={() => toggleDrawer(false, {})}
                    >
                <Paper className='drawerHeader' sx={{backgroundColor: "primary.main"}} elevation={2}>
                    
                    <img src={logoWithTagline} onClick={() => navigate("/")} alt="Logo" className="appBarHeaderLogo"/>                        
                    
                </Paper>
                <List>     
                    {
                        adminMode ? <Divider style={{marginBottom: '5px'}}><Chip label="Admin" /></Divider>:null
                    }    
                    {
                        adminMode ? menuItems.filter(e => !e.noLogin && e.showAdminView && e.section == "admin").map(item => {                            
                            return (
                                <Link to={item.to} key={item.key}>
                                    <ListItem key={""} disablePadding>                        
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>                            
        
                            )
                        
                        }):null
                    }
                    {
                        mapTaskingMode || adminMode ? <Divider style={{marginBottom: '5px'}}><Chip label="Map Review" /></Divider>:null
                    }    
                    {
                        mapTaskingMode || adminMode ? menuItems.filter(e => !e.noLogin && e.showAdminView && e.section == "mapReview").map(item => {

                            return (
                                <Link to={item.to} key={item.key}>
                                    <ListItem key={""} disablePadding>                        
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>                            
        
                            )

                        }):null
                    }
                    {
                        loggedIn ?  <Divider style={{marginBottom: '5px'}}><Chip label="Console" /></Divider>:<></>
                    }                               
                    {
                        loggedIn ? menuItems.filter(e => !e.noLogin && e.showAdminView && e.section == "console").map(item => {
                            return (
                                <Link to={item.to} key={item.key}>
                                    <ListItem key={""} disablePadding>                        
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>                            
        
                            )                            
                        }):null
                    }                             
                    {
                        loggedIn ? menuItems.filter(e => !e.noLogin && e.showAdminView && e.section == "account").map(item => {
                            return (
                                <Link to={item.to} key={item.key}>
                                    <ListItem key={""} disablePadding>                        
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>                            
        
                            )                            
                        }):null
                    } 
                    <Divider style={{marginBottom: '5px', marginTop: '5px'}}><Chip label="General" /></Divider>                    
                    {
                        menuItems.filter(e => e.noLogin && e.section == "general").map(item => {                        
                            return (
                                <Link to={item.to} key={item.key}>
                                    <ListItem key={""} disablePadding>                        
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>                            
        
                            )                        
                        })
                    } 

                </List>                
                </Box>
            </Drawer>
        </>
    )

}


export default Navbar;