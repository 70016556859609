import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography, CircularProgress } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import MailIcon from '@mui/icons-material/Mail';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import './RedirectOnAuth.css'
import { Email } from '@mui/icons-material';


function RedirectOnAuth(props) {

    const currentUser = props?.auth?.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const navigate = useNavigate()
    
    const routeParams = useParams();

    const profile = routeParams.profile
    const route = routeParams.route.replaceAll("-", "/")
    const organizationObj = props.organizationObj;

    console.log(organizationObj)

    var requiredComponents = []

    var text = "Redirecting..."
    switch(profile){
        case "welcome":
            
            requiredComponents = [currentUser!=null, loggedIn, Object.keys(organizationObj?.userOrganizations)?.length > 0, organizationObj?.selectedOrganization != null] 

            text = `Building Profile (${requiredComponents.filter(e => e == true).length} of ${requiredComponents.length})`
            if(requiredComponents.filter(e => e == false).length == 0){
                text = "Redirecting..."
                setTimeout(() => {
                    console.log("Redirecting to /dashboard")
                    console.log(route)
                    navigate(`/${route}`)
                }, 750)
            }
            break;
        default:
            text = "Redirecting..."
            break;

    }

    
    return (
        <div className="redirectOnAuthArea">
            <CircularProgress 
                size={80}
                color='secondary'
                style={{marginBottom: "20px"}}
            />
            <Typography variant="h6" color={'#ffffff'}>{text}</Typography>
        </div>
    )

}


export default RedirectOnAuth