import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './Pricing.css'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import CreditCardIcon from '@mui/icons-material/CreditCard'
import WidgetsIcon from '@mui/icons-material/Widgets';


function Pricing(props) {

    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const navigate = useNavigate()
    const firestore = firebase.firestore();

    const [area, setArea] = useState(500)
    const [pricingSchedule, setPricingSchedule] = useState({})


    useEffect(() => {
        getPricing()
    }, [])

    function formatAsDollar(amount) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2, // This means that fractions might be omitted if they are zero.
            maximumFractionDigits: 2  // Up to 3 decimal places

        }).format(amount);
    }

    function formatAsNumber(amount) {
        var formatter = new Intl.NumberFormat();
        return formatter.format(amount);
    }


    function scaleArea(x) {
        
        return Math.floor(Math.pow(x, 2)/100)
    }
    
    async function getPricing(){

        //get pricing from /AppConfig/GenearalApp doc
        firestore.collection("AppConfig").doc("GeneralApp").get()
        .then((doc) => {
            if(doc.exists){
                setPricingSchedule(doc.data()?.StripePricingSchedule ?? {})
            }else{
                setPricingSchedule({})
            }
        })

    }  
    

    return (
        <div className="pricingContent">
            <div className='pricingContentScroller'>
                <Paper elevation={2} className='pricingContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <WidgetsIcon style={{marginRight: '10px'}}/>
                        Pricing
                    </div>                    
                </Paper>
                <div 
                    className='pricingElementHolder'>
                    {
                        //order pricingSchedule by the key index
                        Object.keys(pricingSchedule)
                            .sort((a, b) => pricingSchedule[a].index - pricingSchedule[b].index)
                            .map((key, index) => {
                                console.log(key)
                                const element = pricingSchedule[key]
                                return (
                                    <Paper className='pricingElement' elevation={2}>
                                        <h2>{element?.name}</h2>
                                        <h5>{element?.description}</h5>
                                        <h4>{element?.tagDescription}</h4>                                    
                                        <ul>
                                            {
                                                element?.features.map((feature) => {
                                                    return (
                                                        <li>{feature}</li>
                                                    )
                                                })
                                            }                                        
                                        </ul>
                                        <div></div>
                                        {
                                            element?.contactUs ? (
                                                <Button 
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => navigate('/contactus')}>
                                                    Contact Us
                                                </Button>
                                            ):(
                                                <span className="pricingSelector">
                                                    {/* <span>
                                                    <Typography id="input-slider" gutterBottom>
                                                        {`Area (${formatAsNumber(area)} mi²)`}
                                                    </Typography>
                                                    <Slider
                                                        value={area}
                                                        onChange={(event, value) => setArea(value)}
                                                        aria-label="Number of Miles"
                                                        defaultValue={500}                                                    
                                                        min={25}
                                                        max={2500}
                                                        valueLabelDisplay="off"
                                                        />
                                                    </span> */}
                                                    {
                                                        
                                                        <h1><span><strong>{`${formatAsDollar(element?.price/100)}/${element?.units} ${element?.billingSchedule}`}</strong></span> </h1>
                                                    
                                                    }
                                                </span>                                       
                                            )

                                        }

                                        {
                                            
                                        }
                                        
                                        
                                    </Paper>
                                )

                            })
                    }
                </div>
            </div>            
            <Footer></Footer>
        </div>
    )

}


export default Pricing