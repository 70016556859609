
import { GoogleMap, GroundOverlay, Polygon, Rectangle, StreetViewPanorama, useLoadScript } from '@react-google-maps/api';
import { Slider, Stack } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import * as turf from '@turf/turf';


function renderAnalyticControl(analytic, analyticStateRef, updateAnalyticState) {

    if (analytic === "PixelGroupChangePercentage") {
        const dataObj = availableAnalytics[analytic].dataObject;
        if(analyticStateRef.current == null) {
            analyticStateRef.current = dataObj;
            updateAnalyticState(dataObj);
        }

        const value = analyticStateRef.current?.threshold ?? dataObj.threshold

        return (
            <div>
                <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}><span>Change Threshold | </span> <span style={{width: '35px', justifyContent: 'flex-end', display: 'flex' }}>{`${value}%`}</span></h5>
                <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                    <Remove />
                    <Slider 
                        aria-label="Volume" 
                        value={value} 
                        min={20}
                        max={100}
                        step={10}
                        marks={false}
                        color='secondary'
                        onChange={(event) => {
                            
                            analyticStateRef.current = {
                                ...analyticStateRef.current,
                                threshold: event.target.value  
                            }

                            updateAnalyticState(analyticStateRef.current)
                        }} />
                    <Add />
                </Stack>
            </div>
        );
    }
}

// Now we can batch merge these polygons
function batchUnion(polygons) {
    if (polygons.length === 0) return null;
    
    let combinedPolygon = polygons[0];
  
    for (let i = 1; i < polygons.length; i++) {
        try{    
            combinedPolygon = turf.union(combinedPolygon, polygons[i]);
        }catch(e) {
            console.log(e);
        }

    }
  
    return combinedPolygon;
  }

function generatePolygonFromSquare(x, y, metaData) {

    const xPixel = metaData.numberOfPixels * x
    const yPixel = metaData.numberOfPixels * y

    const pixelArray = [
        [xPixel, yPixel],
        [xPixel + metaData.numberOfPixels, yPixel],
        [xPixel + metaData.numberOfPixels, yPixel + metaData.numberOfPixels],
        [xPixel, yPixel + metaData.numberOfPixels],
    ]

    pixelArray.forEach((corner) => {
        if(corner[0] == 0) {
            corner[0] = metaData.bottomLeft.lng;
        }else if(corner[0] >= metaData.width) {
            corner[0] = metaData.bottomLeft.lng + .25;
        }else{
            corner[0] = metaData.squareLatLngs.lngs[corner[0]];
        }

        if(corner[1] == 0) {
            corner[1] = metaData.bottomLeft.lat + .25;
        }else if(corner[1] >= metaData.height) {
            corner[1] = metaData.bottomLeft.lat;
        }
        else{
            corner[1] = metaData.squareLatLngs.lats[corner[1]];
        }
    })

    //append first point to end to close the polygon
    pixelArray.push(pixelArray[0]);
    return turf.polygon([pixelArray]);
}

function renderPixelGroupChangePercentage(data, analyticStateRef, updateAnalyticState) {
    
    var finalCombinedPolygon = null;

    if(analyticStateRef.current.savedViews[analyticStateRef.current.threshold] != null) {
        finalCombinedPolygon = analyticStateRef.current.savedViews[analyticStateRef.current.threshold];
    }else{
        var squaresToDisplay = [];
        // foreahc in pixelGroupChangePercentage
        data.pixelGroupChangePercentage.forEach((rowArray, y) => {
            rowArray.forEach((pixel, x) => {
                if(pixel > analyticStateRef.current.threshold) {
                    squaresToDisplay.push(generatePolygonFromSquare(x, y, data.metaData));
                }
            })
        });

        const batchSize = 100;
        let batchedPolygons = [];
        
        for (let i = 0; i < squaresToDisplay.length; i += batchSize) {
            const batch = squaresToDisplay.slice(i, i + batchSize);
            const combinedBatch = batchUnion(batch);
            batchedPolygons.push(combinedBatch);
        }
        
        // Combine the batched polygons into a final MultiPolygon
        finalCombinedPolygon = batchUnion(batchedPolygons);

        if(finalCombinedPolygon == null) {
            return null;
        }
        const polygons = finalCombinedPolygon.geometry.coordinates.map(ring => {
            return ring[0].map(coord => { 
                return { lat: coord[1], lng: coord[0] }
            });
        });
          

        if(analyticStateRef.current.savedViews[analyticStateRef.current.threshold] == null && polygons != null) {
            
            analyticStateRef.current = {
                ...analyticStateRef.current,
                savedViews: {
                    ...analyticStateRef.current.savedViews,
                    [analyticStateRef.current.threshold]: polygons
                }
            }            
            updateAnalyticState(analyticStateRef.current);
        }
    }


    const bottomLeftCoords = {lat: data.metaData.bottomLeft.lat, lng: data.metaData.bottomLeft.lng};
    const path = [
        bottomLeftCoords,
        {lat: bottomLeftCoords.lat, lng: data.metaData.bottomLeft.lng + .25},
        {lat: bottomLeftCoords.lat + .25, lng: data.metaData.bottomLeft.lng + .25},
        {lat: bottomLeftCoords.lat + .25, lng: data.metaData.bottomLeft.lng},
        bottomLeftCoords
    ]

    if(analyticStateRef.current.savedViews[analyticStateRef.current.threshold] != null) {
        return <Polygon 
                    key={`pixelGroupChangePercentage-${analyticStateRef.current.threshold}`}
                    paths={analyticStateRef.current.savedViews[analyticStateRef.current.threshold]}
                    options={{
                        strokeColor: "#f9a825",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#f9a825",
                        fillOpacity: 0,
                        zIndex: 1000
                    }}
                />
    }else{
        return null;
    }

    
}



export const availableAnalytics = {

    "PixelGroupChangePercentage": {
        "displayName": "Change Threshold",
        "render": renderPixelGroupChangePercentage,
        "control": (analyticStateRef, updateAnalyticState) => { return renderAnalyticControl("PixelGroupChangePercentage", analyticStateRef, updateAnalyticState)},
        "dataObject": {
            "threshold": 100,
            "savedViews": {}
        }
    }
}

