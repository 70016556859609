import React, { useEffect, useState } from 'react';
import { TextField, IconButton, Skeleton } from '@mui/material';

import { Cancel, Save, Edit } from '@mui/icons-material'; 

function HeaderTitleEditable({ label, value, permissionToEdit, saveChanges, loading }) {

  const [editing, setEditing] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(value);
  const [touchecd, setTouched] = useState(false)

  
  useEffect(() => {
    if(value != updatedValue && !touchecd){
        setUpdatedValue(value)
        setTouched(true)
    }
  }, [value])

  return (
    loading ? <Skeleton height={60} width={210} /> :
    editing ? 
            <>
                <TextField
                    label={label}
                    type="text"
                    value={updatedValue}
                    onChange={(e) => setUpdatedValue(e.target.value)}
                    size='small'
                    style={{marginRight: '10px'}}
                    />
                    <IconButton 
                        onClick={() => {
                            saveChanges(updatedValue)
                            .then(() => {
                                setEditing(false)
                            })
                            .catch((error) => {
                                console.error(error)
                            })

                        }}
                        disabled={value == updatedValue}>
                        <Save />
                    </IconButton>
                    <IconButton onClick={() => {
                        setEditing(false)
                    }}>
                        <Cancel />
                    </IconButton>
            </>
            :
            <div
                onClick={() => {
                    if(permissionToEdit){
                        setEditing(!editing)
                    }
                }} 
                className={permissionToEdit ? "clickable":"" }
                style={
                    {display: 'flex', flexDirection: 'row', alignItems: 'center'}
                }>
                <h2>{value}</h2>      
                {
                    permissionToEdit ? <Edit /> : null
                }           
            </div>

  );
}

export default HeaderTitleEditable;
