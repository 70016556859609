import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './AdminDashboard.css'

import Footer from '../Footer/Footer'
import { fetchFromAPI } from '../../functions/fetchFromAPI';
import { Button, Chip, Paper, Skeleton } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';


import { Dashboard, Public } from '@mui/icons-material';

function AdminDashboard(props) {

    const adminMode = props.adminMode
    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const adminDashboardObj = props?.adminDashboardObj
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const navigate = useNavigate()

    const [earthEngineActiveTasks, setEarthEngineActiveTasks] = useState([])
    const [fetchingEarthEngineActiveTasks, setFetchingEarthEngineActiveTasks] = useState(true)

    useEffect(() => {

        if (adminMode) {
            getEarthEngineActiveTasks()
        }
    }, [])

    
    function getEarthEngineActiveTasks() {
        setFetchingEarthEngineActiveTasks(true)

        //fetchFromApi
        fetchFromAPI('loteagle', 'get_ee_active_tasks', {method: 'GET'})
        .then((response) => {
            console.log(response)
            const activeTasks = response.activeTasks ?? []
            setEarthEngineActiveTasks(activeTasks)
            setFetchingEarthEngineActiveTasks(false)
        })
        .catch((error) => {
            console.error(error)
            createAlert('error', error.message)
            setEarthEngineActiveTasks([])
        })
        .finally(() => {
            setFetchingEarthEngineActiveTasks(false)
        })



    }


    

    return (
        <div className="adminDashboardContent">
            <div className='adminDashboardContentScroller'>
                <Paper elevation={2} className='adminDashboardContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <Dashboard style={{marginRight: '10px'}}/>
                        Admin Dashboard
                    </div>
                    
                    <div className='adminDashboardContentArea'>
                        
                        <h2>Earth Engine Active Tasks</h2>
                        <div className='earthEngineActiveTasks'>
                            {
                                fetchingEarthEngineActiveTasks ? 
                                <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
                                :earthEngineActiveTasks.map((task, index) => {
                                    return (
                                        <Chip
                                            key={task}
                                            label={task}
                                            color='secondary'
                                            style={{margin: '5px'}}/>
                                    )
                                })
                            }                            
                            {
                                earthEngineActiveTasks.length === 0 && !fetchingEarthEngineActiveTasks ? <h3 style={{margin: '0px'}}>No active tasks</h3> : null
                            }
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<Public />}
                            disabled={fetchingEarthEngineActiveTasks}   
                            onClick={() => {
                                getEarthEngineActiveTasks()
                            }}>
                                Get Active Tasks
                        </Button>
                    </div>
                </Paper>
                
            </div>            
            <Footer></Footer>
            
        </div>
    )

}


export default AdminDashboard