import { Chip } from '@mui/material';

export const subscriptionStatuses = {
    trialing: <Chip size='small' color='stripeGreen' label='Trialing'/>,
    active: <Chip size='small' color='stripeGreen' label='Active'/>,
    incomplete: <Chip size='small' color='stripeYellow' label='Incomplete'/>,
    incomplete_expired: <Chip size='small' color='stripeRed' label='Incomplete Expired'/>,
    past_due: <Chip size='small' color='stripeYellow' label='Past Due'/>,
    canceled: <Chip size='small' color='stripeGray' label='Canceled'/>,
    unpaid: <Chip size='small' color='stripeYellow' label='Unpaid'/>,
    paused: <Chip size='small' color='stripeGray' label='Paused'/>,
    cancelRequested: <Chip size='small' color='stripeYellow' label='Canceled' style={{marginLeft: '5px'}}/>,
}


export const invoiceStatuses = {
    draft: <Chip size='small' color='stripeYellow' label='Draft'/>,
    deleted: <Chip size='small' color='stripeRed' label='Deleted'/>,
    open: <Chip size='small' color='stripeYellow' label='Open'/>,
    paid: <Chip size='small' color='stripeGreen' label='Paid'/>,
    uncollectible: <Chip size='small' color='stripeRed' label='Uncollectible'/>,
    void: <Chip size='small' color='stripeGray' label='Void'/>
}