import usZipCodes from '../assets/usZipCodes.json'

export function returnZipCodeLatLng(_zipCode){
    const tempIntCode = parseInt(_zipCode)
    if(tempIntCode != null && usZipCodes[tempIntCode] != null){
        return {lat: usZipCodes[tempIntCode].lat, lng: usZipCodes[tempIntCode].lng}
    }
    else{
        for (let i = 1; i < 6; i++) {
            if(usZipCodes[tempIntCode - i] != null){
                return {lat: usZipCodes[tempIntCode - i].lat, lng: usZipCodes[tempIntCode - i].lng}
            }else if(usZipCodes[tempIntCode + i] != null){
                return {lat: usZipCodes[tempIntCode + i].lat, lng: usZipCodes[tempIntCode + i].lng}
            }
        }            
    }
}