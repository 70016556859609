import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import firebase from 'firebase/compat/app'
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Paper, Slider, } from '@mui/material';

import { GoogleMap, StreetViewPanorama, useLoadScript, Marker, Polygon, GroundOverlay, OverlayView, Rectangle } from '@react-google-maps/api';
import { getStorage, ref, listAll, getDownloadURL, getMetadata, uploadBytes } from "firebase/storage";
import { ArrowBack, ArrowCircleLeft, CheckCircleOutline, CloseFullscreen, CloudUpload, Collections, Dangerous, Event, ExitToApp, Gif, GridView, HideImage, Photo, Publish, Save, SelectAll,  } from '@mui/icons-material';

import './AdminMapReview.css';
import settings from '../../settings.json';
import googleMapsStyle from '../../assets/googleMapsStyle.json'

import CountdownTimerButton from '../CountdownTimerButton/CountdownTimerButton'

import { calculatePolygonBounds } from '../../functions/calculatePolygonBounds'
import { calculatePolygonsIntersect } from '../../functions/calculatePolygonsIntersect'
import { calculateProcessingGridSquareBounds } from '../../functions/calculateProcessingGridSquareBounds'
import { createAdminSquareFromId } from '../../functions/createAdminSquareFromId'
import { calculateSquareLotsOverlapMask } from '../../functions/calculateSquareLotsOverlapMask'
import { calculateSquareLotsOverlap } from '../../functions/calculateSquareLotsOverlap'
import { dateIdToUTCDateObject } from '../../functions/dateIdToUTCDateObject'
import { dateIdToAnalyticsRequestId } from '../../functions/dateIdToAnalyticsRequestId'
import { dateIdToAnalyticsRequestIdShorthand } from '../../functions/dateIdToAnalyticsRequestIdShorthand'
import { dateToLocaleUTCDateString } from '../../functions/dateToLocaleUTCDateString'
import { findClosestObjectId } from '../../functions/findClosestObjectId'

import { create } from '@mui/material/styles/createTransitions';


function AdminMapReview(props) {

  const user = props.user;
  const createAlert = props.createAlert
  const localhost = settings.localhost;
  const navigate = useNavigate()

  const numberOfGifSections = 10
  const squareSize = .25

  const reviewMode = props.reviewMode
  const routeParams = useParams()

  const propsSquareId = routeParams.squareId
  const propsTaskId = routeParams.taskId
  const propsReviewerId = routeParams.reviewerId

  const activeUserId = propsReviewerId != null ? propsReviewerId : user.uid


  const theme = useTheme();
  const firestore = firebase.firestore();
  const storage = getStorage();
  const {isLoaded} = useLoadScript({googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"})

  const mapRef = useRef(null);
  const pegmanListerTimeoutCount = useRef(0)
  
  const lotsRef = useRef({})
  const squaresRef = useRef({})

  const currentSquareLotOverlapRef = useRef(null)
  const lastViewportRenderRef = useRef(0)
  const lastViewportRenderTimeoutRef = useRef(null)
  const currentLocationRef  = useRef({lat: 36.1627, lng: -86.7816});  
  const squarePngImageryRef = useRef({})
  const squareGifImageryRef = useRef({})
  const squareAnalyticsRef = useRef({})

  const [refreshKey, setRefreshKey] = useState(0)
  const [mapZoom, setMapZoom] = useState(12)
  const [location, setLocation] = useState({lat: currentLocationRef.current.lat, lng: currentLocationRef.current.lng,})
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const [mapIsStreetViewVisible, setMapIsStreetViewVisible] = useState(false);
  const [mapPanoramaIsDragging, setMapPanoramaIsDragging] = useState(false);
  const [visibleLotsArray, setVisibleLotsArray] = useState([])
  const [visibleSquaresArray, setVisibleSquaresArray] = useState([])
  const [confirmExitWithoutSavingDialogOpen, setConfirmExitWithoutSavingDialogOpen] = useState(false)
  const [squareGifImagery, setSquareGifImagery] = useState(null)
  const [submitChangesDialogOpen, setSubmitChangesDialogOpen] = useState(false)

  const [publishChangesDialogOpen, setPublishChangesDialogOpen] = useState(false)
  const [publishChangesDialogOpenTime, setPublishChangesDialogOpenTime] = useState(null)
  const [savingChangesLoading, setSavingChangesLoading] = useState(false)
  const [completeAnalyticProcessingDialogOpen, setCompleteAnalyticProcessingDialogOpen] = useState(false)
  const [completeAnalyticProcessingDialogOpenTime, setCompleteAnalyticProcessingDialogOpenTime] = useState(null)

  

  const currentSquareRef = useRef(null)
  const currentSquarePngDateObjectRef = useRef(null)
  const currentSquareObjectRef = useRef(null)
  const [currentSquare, setCurrentSquare] = useState(null)
  const [currentSquareLotMask, setCurrentSquareLotMask] = useState(null)
  const [currentSquareObject, setCurrentSquareObject] = useState(null)
  const [currentSquarePngDates, setCurrentSquarePngDates] = useState(null)
  const [currentSquareVisiblePngs, setCurrentSquareVisiblePngs] = useState(null)
  const [currentSquarePngDateObject, setCurrentSquarePngDateObject] = useState(null)
  const [currentSquareSelectedImageryType, setCurrentSquareSelectedImageryType] = useState("png")

  const [squareAnalytics, setSquareAnalytics] = useState({})

  const dateSliderMarksRef = useRef(null)
  const [dateSliderMarks, setDateSliderMarks] = useState(null)
  const [dateSliderMinDate, setDateSliderMinDate] = useState(null)
  const [dateSliderMaxDate, setDateSliderMaxDate] = useState(null)
  const [dateSliderValue, setDateSliderValue] = useState(null)
  const [activeDateObject, setActiveDateObject] = useState(null)

  const processingAnalyticChangesRef = useRef(null)
  const processingAnalyticIdRef = useRef(null)
  const processingAnalyticIdShorthandRef = useRef(null)
  const processingAnalyticLastSavedState = useRef(null)
  const [processingAnalyticSaveNecessary, setProcessingAnalyticSaveNecessary] = useState(false) 
  const [processingAnalyticId, setProcessingAnalyticId] = useState(null)
  const [processingAnalyticIdShorthand, setProcessingAnalyticIdShorthand] = useState(null)
  const [processingAnalyticChanges, setProcessingAnalyticChanges] = useState(null)

  const taskObjectRef = useRef(null)
  const taskReviewObjectRef = useRef(null)
  const [taskObject, setTaskObject] = useState(null)
  const [taskReviewObject, setTaskReviewObject] = useState(null)
  const [taskReviewIndexOfKey, setTaskReviewIndexOfKey] = useState(null)

  const [processingAnalyticGrid00, setProcessingAnalyticGrid00] = useState(null)  
  const [processingAnalyticGrid01, setProcessingAnalyticGrid01] = useState(null)
  const [processingAnalyticGrid02, setProcessingAnalyticGrid02] = useState(null)
  const [processingAnalyticGrid03, setProcessingAnalyticGrid03] = useState(null)
  const [processingAnalyticGrid04, setProcessingAnalyticGrid04] = useState(null)
  const [processingAnalyticGrid05, setProcessingAnalyticGrid05] = useState(null)
  const [processingAnalyticGrid10, setProcessingAnalyticGrid10] = useState(null)
  const [processingAnalyticGrid11, setProcessingAnalyticGrid11] = useState(null)
  const [processingAnalyticGrid12, setProcessingAnalyticGrid12] = useState(null)
  const [processingAnalyticGrid13, setProcessingAnalyticGrid13] = useState(null)
  const [processingAnalyticGrid14, setProcessingAnalyticGrid14] = useState(null)
  const [processingAnalyticGrid15, setProcessingAnalyticGrid15] = useState(null)
  const [processingAnalyticGrid20, setProcessingAnalyticGrid20] = useState(null)
  const [processingAnalyticGrid21, setProcessingAnalyticGrid21] = useState(null)
  const [processingAnalyticGrid22, setProcessingAnalyticGrid22] = useState(null)
  const [processingAnalyticGrid23, setProcessingAnalyticGrid23] = useState(null)
  const [processingAnalyticGrid24, setProcessingAnalyticGrid24] = useState(null)
  const [processingAnalyticGrid25, setProcessingAnalyticGrid25] = useState(null)
  const [processingAnalyticGrid30, setProcessingAnalyticGrid30] = useState(null)
  const [processingAnalyticGrid31, setProcessingAnalyticGrid31] = useState(null)
  const [processingAnalyticGrid32, setProcessingAnalyticGrid32] = useState(null)
  const [processingAnalyticGrid33, setProcessingAnalyticGrid33] = useState(null)
  const [processingAnalyticGrid34, setProcessingAnalyticGrid34] = useState(null)
  const [processingAnalyticGrid35, setProcessingAnalyticGrid35] = useState(null)
  const [processingAnalyticGrid40, setProcessingAnalyticGrid40] = useState(null)
  const [processingAnalyticGrid41, setProcessingAnalyticGrid41] = useState(null)
  const [processingAnalyticGrid42, setProcessingAnalyticGrid42] = useState(null)
  const [processingAnalyticGrid43, setProcessingAnalyticGrid43] = useState(null)
  const [processingAnalyticGrid44, setProcessingAnalyticGrid44] = useState(null)
  const [processingAnalyticGrid45, setProcessingAnalyticGrid45] = useState(null)
  const [processingAnalyticGrid50, setProcessingAnalyticGrid50] = useState(null)
  const [processingAnalyticGrid51, setProcessingAnalyticGrid51] = useState(null)
  const [processingAnalyticGrid52, setProcessingAnalyticGrid52] = useState(null)
  const [processingAnalyticGrid53, setProcessingAnalyticGrid53] = useState(null)
  const [processingAnalyticGrid54, setProcessingAnalyticGrid54] = useState(null)
  const [processingAnalyticGrid55, setProcessingAnalyticGrid55] = useState(null)

  
  const processingGridSize = 6
  const processingAnalyticGrid = {
    "00": [processingAnalyticGrid00, setProcessingAnalyticGrid00],
    "01": [processingAnalyticGrid01, setProcessingAnalyticGrid01],
    "02": [processingAnalyticGrid02, setProcessingAnalyticGrid02],
    "03": [processingAnalyticGrid03, setProcessingAnalyticGrid03],
    "04": [processingAnalyticGrid04, setProcessingAnalyticGrid04],
    "05": [processingAnalyticGrid05, setProcessingAnalyticGrid05],
    "10": [processingAnalyticGrid10, setProcessingAnalyticGrid10],
    "11": [processingAnalyticGrid11, setProcessingAnalyticGrid11],
    "12": [processingAnalyticGrid12, setProcessingAnalyticGrid12],
    "13": [processingAnalyticGrid13, setProcessingAnalyticGrid13],
    "14": [processingAnalyticGrid14, setProcessingAnalyticGrid14],
    "15": [processingAnalyticGrid15, setProcessingAnalyticGrid15],
    "20": [processingAnalyticGrid20, setProcessingAnalyticGrid20],
    "21": [processingAnalyticGrid21, setProcessingAnalyticGrid21],
    "22": [processingAnalyticGrid22, setProcessingAnalyticGrid22],
    "23": [processingAnalyticGrid23, setProcessingAnalyticGrid23],
    "24": [processingAnalyticGrid24, setProcessingAnalyticGrid24],
    "25": [processingAnalyticGrid25, setProcessingAnalyticGrid25],
    "30": [processingAnalyticGrid30, setProcessingAnalyticGrid30],
    "31": [processingAnalyticGrid31, setProcessingAnalyticGrid31],
    "32": [processingAnalyticGrid32, setProcessingAnalyticGrid32],
    "33": [processingAnalyticGrid33, setProcessingAnalyticGrid33],
    "34": [processingAnalyticGrid34, setProcessingAnalyticGrid34],
    "35": [processingAnalyticGrid35, setProcessingAnalyticGrid35],
    "40": [processingAnalyticGrid40, setProcessingAnalyticGrid40],
    "41": [processingAnalyticGrid41, setProcessingAnalyticGrid41],
    "42": [processingAnalyticGrid42, setProcessingAnalyticGrid42],
    "43": [processingAnalyticGrid43, setProcessingAnalyticGrid43],
    "44": [processingAnalyticGrid44, setProcessingAnalyticGrid44],
    "45": [processingAnalyticGrid45, setProcessingAnalyticGrid45],
    "50": [processingAnalyticGrid50, setProcessingAnalyticGrid50],
    "51": [processingAnalyticGrid51, setProcessingAnalyticGrid51],
    "52": [processingAnalyticGrid52, setProcessingAnalyticGrid52],
    "53": [processingAnalyticGrid53, setProcessingAnalyticGrid53],
    "54": [processingAnalyticGrid54, setProcessingAnalyticGrid54],
    "55": [processingAnalyticGrid55, setProcessingAnalyticGrid55],
  }

  useEffect(() => {
    init()
  },[])

  useEffect(() => {
    if(currentSquare != null){
      renderDateSlider(currentSquarePngDates)
    }

  },[currentSquarePngDates, currentSquare])

  useEffect(() => {    
    if(currentSquare == null){
      clearSquareState()
    }else{
      setVisibleLotsArray([])
      setVisibleSquaresArray([])
    }
  },[currentSquare])



  const onStreetViewLoad = useCallback((streetViewPanorama) => {
    // Check if the user is in Street View mode
    setMapIsStreetViewVisible(streetViewPanorama.getVisible());

    // Listen for changes to the visibility of Street View
    streetViewPanorama.addListener("visible_changed", () => {
      setMapIsStreetViewVisible(streetViewPanorama.getVisible());
      setMapPanoramaIsDragging(false)
    });
    

  }, []);

 
  function init() {

    initReviewMode()
    
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }

  }



  function initReviewMode(){

    if(reviewMode == "reviewMode"){
      console.log("Review Mode")
      getAllLots()
      getAllSquares()
    }else if(reviewMode == "taskMode" || reviewMode == "validateMode"){
      //get task element from firestore
      const taskRef = firestore.collection('Operations').doc('LotOperations').collection('AnalyticRequests').doc(propsTaskId)
      taskRef.get().then(async (doc) => {
        if (doc.exists) {
          const taskData = doc.data()
          taskObjectRef.current = taskData
          setTaskObject(taskData)
          if(taskData?.manualProcessingObj?.manualProcessingComplete == true){
            createAlert("info", "This task has already been reviewed", 5000)
          }
          if(taskData?.manualProcessingObj?.squareStatus?.[propsSquareId]?.reviewers?.[activeUserId]?.active == true){
            const reviewObject = taskData?.manualProcessingObj?.squareStatus?.[propsSquareId]?.reviewers?.[activeUserId] 
            taskReviewObjectRef.current = reviewObject
            setTaskReviewObject(reviewObject)
            const indexOfKey = taskData?.requiredSquares?.map(e => e.replaceAll(".","")).indexOf(propsSquareId)
            setTaskReviewIndexOfKey(indexOfKey)
            try{
              await getTaskLot(taskData.lotId)
              await getTaskSquare(propsSquareId)
              try{
                panToBounds(squaresRef.current[propsSquareId].bounds)
              }catch(err){
              }
              await selectSquare(propsSquareId)
              const taskDateObj = squarePngImageryRef.current[propsSquareId][taskData?.requiredDates?.[taskData?.requiredDates.length - 1]]
              const taskDateMilliseconds = taskDateObj.displayDate.getTime()
              await handleDateSliderChange(taskDateMilliseconds)

              const compositeSquareDateRangeId = `${propsSquareId}-${taskData?.dateRangeId}`
              selectAnalytic(propsSquareId, compositeSquareDateRangeId)


            }catch(err){  
              console.error(err)
              //redirect to the task page
              //throw new Error("Error getting task data")
            }

          }else{
            throw new Error("User does not have access to this task")
          }
        } else {
          //redirect to the task page
          throw new Error("Task does not exist")
        }
      }).catch((error) => {

        createAlert('error', error.message ? error.message:"There was an error", 5000)
        //redirect to the task page
        navigate('/Tasking')
      });

    }
  }
  
  function handleKeyDown(event){
    if(event.keyCode == 87){
      //w key
      changeSelectedImgType("png")
    }else if(event.keyCode == 69){
      //e key
      changeSelectedImgType("gif")
    }else if(event.keyCode == 82){
      //r key
      changeSelectedImgType("gmapSat")
    }else if(event.keyCode == 83){
      //s key
      changeSelectedImgType("png")
      //set the date to the first date in the dateSliderMarks
      handleDateSliderChange(dateSliderMarksRef.current?.[0]?.value)
    }else if(event.keyCode == 68){
      //d key
      changeSelectedImgType("png")
      //set the date to the middle date in the dateSliderMarks
      handleDateSliderChange(dateSliderMarksRef.current?.[Math.floor(dateSliderMarksRef.current.length / 2)]?.value)       
    }else if(event.keyCode == 70){
      //f key
      changeSelectedImgType("png")
      //set the date to the last date in the dateSliderMarks
      handleDateSliderChange(dateSliderMarksRef.current?.[dateSliderMarksRef.current.length - 1]?.value)
    }else{
      //console.log(event.keyCode)
    }

  }

  function changeSelectedImgType(_type){

    switch(_type){
      case "png":
        setCurrentSquareSelectedImageryType("png")
        break;
      case "gif":
        setCurrentSquareSelectedImageryType("png")
        setTimeout(() => {
          setCurrentSquareSelectedImageryType("gif")        
        }, 1);
        break;
      case "gmapSat":
        setCurrentSquareSelectedImageryType(null)
        toggleGoogleMapType("satellite")
        break;
      case "none":
        setCurrentSquareSelectedImageryType(null)
      default:
        setCurrentSquareSelectedImageryType(null)
        break;
    }
  }

  function panToBounds(_bounds){
    if(mapRef.current){
      mapRef.current.fitBounds(_bounds)
    }
  }

  function clearSquareState(){
    currentSquareRef.current = null
    currentSquarePngDateObjectRef.current = null
    currentSquareLotOverlapRef.current = null
    currentSquareObjectRef.current = null
    dateSliderMarksRef.current = null
    setCurrentSquareLotMask(null)
    setCurrentSquareObject(null)
    setCurrentSquarePngDates(null)
    setCurrentSquareVisiblePngs(null)
    setDateSliderMarks(null)
    setDateSliderMinDate(null)
    setDateSliderMaxDate(null)
    setDateSliderValue(null)
    setActiveDateObject(null)
    setCurrentSquarePngDateObject(null)
    changeSelectedImgType("png")
    clearProcessingAnalyticState()
    setMapPanoramaIsDragging(false)
  }

  function clearProcessingAnalyticState(){
    processingAnalyticChangesRef.current = null
    processingAnalyticIdRef.current = null
    processingAnalyticLastSavedState.current = null
    processingAnalyticIdShorthandRef.current = null
    setProcessingAnalyticId(null) 
    setProcessingAnalyticIdShorthand(null)
    setProcessingAnalyticSaveNecessary(false)
    changeSelectedImgType("png")
    setProcessingAnalyticChanges(null)
    setMapPanoramaIsDragging(false)

    //foreach processingAnalyticGrid, set the state to null
    Object.keys(processingAnalyticGrid).forEach((key) => {
      processingAnalyticGrid[key][1](null)
    })


  }

  function openPublishDialog(_open){
    if(_open){      
      const date = new Date()
      //add 5 seconds to the current date
      date.setSeconds(date.getSeconds() + 5.99)
      setPublishChangesDialogOpenTime(date.getTime())
      if(reviewMode == "taskMode"){
        setSubmitChangesDialogOpen(true)
      }else{
        setPublishChangesDialogOpen(true)

      }
    }else{
      setPublishChangesDialogOpen(false)
      setSubmitChangesDialogOpen(false)
      setPublishChangesDialogOpenTime(null)
    }
  }

  function openCompleteAnalyticProcessingDialog(_open){

    
    
    if(_open){
      const date = new Date()
      //add 5 seconds to the current date
      date.setSeconds(date.getSeconds() + 3.99)
      setCompleteAnalyticProcessingDialogOpenTime(date.getTime())

      setCompleteAnalyticProcessingDialogOpen(true)
    }else{
      setCompleteAnalyticProcessingDialogOpen(false)
      setCompleteAnalyticProcessingDialogOpenTime(null)
    }

  }

  function mapHandleOnLoad(map) {
    console.log("map loaded", map)
    mapRef.current = map;    

    initPegmanListener()

  };

  function initPegmanListener(){

    pegmanListerTimeoutCount.current += 1
    // Select all elements with the class "gm-svpc"
    const elements = document.querySelectorAll('.gm-svpc');
    if(elements.length == 0 && pegmanListerTimeoutCount.current < 20){      
      setTimeout(() => {
        initPegmanListener()
      }, 500);      
    }else{

      // Add event listeners to each element
      elements.forEach(element => {
        element.addEventListener('mousedown', () => {
          setMapPanoramaIsDragging(true)       
        });
      });
      // Cleanup function to remove event listeners when component unmounts
      return () => {
        elements.forEach(element => {          
          element.removeEventListener('mousedown', () => {     

          });
        });
      };
    }
  }

  function requestViewportRender(){
    if (lastViewportRenderTimeoutRef.current) {
      clearTimeout(lastViewportRenderTimeoutRef.current)
    }
    if(Date.now() - lastViewportRenderRef.current > 250){
      renderViewport()
    }else{
      lastViewportRenderTimeoutRef.current = setTimeout(() => {
        renderViewport()
        lastViewportRenderTimeoutRef.current = null
      }, 250)
    }
  }

  function renderViewport(){
    try{
      lastViewportRenderRef.current = Date.now()

      if (mapRef.current) {

        const bounds = mapRef.current.getBounds()
        const northWest = bounds.getNorthEast()
        const southEast = bounds.getSouthWest()

        var tempVisibleLotsArray = []
        var tempVisibleSquaresArray = []

        if(currentSquareRef.current == null){
          
          tempVisibleSquaresArray = Object.keys(squaresRef.current).filter(key => {
            const square = squaresRef.current[key]
            //if any of the bounds are within the viewport
            if(square.bounds.south < northWest.lat() && square.bounds.north > southEast.lat() && square.bounds.east > southEast.lng() && square.bounds.west < northWest.lng()){
              return true
            }else{
              return false
            }          
          })

          tempVisibleLotsArray = Object.keys(lotsRef.current).filter(key => {
            const lot = lotsRef.current[key]
            //if any of the bounds are within the viewport
            if(lot.bounds.south < northWest.lat() && lot.bounds.north > southEast.lat() && lot.bounds.east > southEast.lng() && lot.bounds.west < northWest.lng()){
              return true
            }else{
              return false
            }          
          })

          // only rerender if the visibleLotsArray has changed
          if(JSON.stringify(visibleLotsArray) !== JSON.stringify(tempVisibleLotsArray)){
            setVisibleLotsArray(tempVisibleLotsArray)
          }

          if(JSON.stringify(visibleSquaresArray) !== JSON.stringify(tempVisibleSquaresArray)){
            setVisibleSquaresArray(tempVisibleSquaresArray)
          }
          
        }else{
                    
          




        }
        
        
      }
    }catch(err){
      console.error(err)
    }

    
  }

  function mapHandleBoundsChanged(){
    try{
      console.log("bounds changed")
      if (mapRef.current) {
        

        requestViewportRender()
      }
    }catch(err){
      console.error(err)
    }
  }

  function getTaskLot(_lotId){
    return new Promise((resolve, reject) => {
      var ref = firestore.collection('Operations').doc('DataLookupTables').collection('Lots').doc(_lotId);
      ref.get().then((doc) => {
        const tempLots = {}

        if(doc.exists){
          tempLots[doc.id] = doc.data()
          tempLots[doc.id].bounds = calculatePolygonBounds(doc.data().polygon)
        }

        lotsRef.current = tempLots
        //requestViewportRender()
        resolve()

      })
      .catch((error) => {
        console.error(error)
        createAlert('error', error)
        reject()
      });
    })

  }

  function getTaskSquare(_squareId){
    return new Promise((resolve, reject) => {
      //check to see if the _squareId folder exists in the AdminData folder
      const detectedChangesRef = ref(storage, `AdminData/${_squareId}`)
      listAll(detectedChangesRef).then((res) => {
        //if there is content in the folder then the square exists
        if(res.prefixes.length > 0){

          var tempSquareElements = {}
          tempSquareElements[_squareId] = createAdminSquareFromId(_squareId)
          squaresRef.current = tempSquareElements
          //requestViewportRender()
          resolve()

        }else{
          createAlert('error', 'The selected square does not exist')
          reject()
        }
      })
      .catch((error) => {
        console.error(error)
        createAlert('error', error)
        reject()
      });
    })
  }

  function getAllLots(){
    //get all the lots from Operations/DataLookupTables/Lots
    var ref = firestore.collection('Operations').doc('DataLookupTables').collection('Lots');
    ref.get().then((querySnapshot) => {
      const tempLots = {}
      querySnapshot.forEach((doc) => {
        tempLots[doc.id] = doc.data()
        tempLots[doc.id].bounds = calculatePolygonBounds(doc.data().polygon)

      });
      lotsRef.current = tempLots
      requestViewportRender()

    })
    .catch((error) => {
      console.error(error)
      createAlert('error', error)
    });
  }

  function getAllSquares(){
    
    //check which folders exist in the AdminData folder
    const detectedChangesRef = ref(storage, `AdminData`)
    // get all the folders in the detectedChangesRef
    listAll(detectedChangesRef).then((res) => {
      var tempSquareElements = {}
      res.prefixes.forEach(prefix => {
        tempSquareElements[prefix.name] = createAdminSquareFromId(prefix.name)
      });
      squaresRef.current = tempSquareElements
      requestViewportRender()
    })
    .catch((error) => {
      console.error(error)
      createAlert('error', error)
    });

  }

  function getSquareGifStatus(_squareId){
    return new Promise((resolve, reject) => {
      if(_squareId != null){
        if(squareGifImageryRef.current[_squareId] == null){
          squareGifImageryRef.current[_squareId] = {}

          //list all the files in the square/Pngs folder
          const adminGifsRef = ref(storage, `AdminData/${_squareId}/Imagery/Gifs`)
          listAll(adminGifsRef).then((res) => {      
            const promiseArray = []
            const itemRefArray = []
            res.prefixes.forEach((prefixRef) => {
              const analyticId = prefixRef.fullPath.split("/")[prefixRef.fullPath.split("/").length - 1]
              squareGifImageryRef.current[_squareId][analyticId] = {
                elementUrls: {},
                loaded: false
              }              
            }) 
            setSquareGifImagery(squareGifImageryRef.current)
            resolve()         
          })
          .catch((error) => {
            console.error(error)          
            reject()
          })
        }
      }else{
        resolve()
      }
    })
  }

  function getSquareGifImagery(_squareId, _analyticId){
    
    if(squareGifImageryRef.current?.[_squareId]?.[_analyticId]?.loaded == false){

      //calcuate overlap object 

      
      const square = squaresRef.current[_squareId]
      const overlappingLotPolygons = Object.values(lotsRef.current).filter(lot => {
        // check if bounds intersect with square bounds
        if(lot.bounds.south < square.bounds.north && lot.bounds.north > square.bounds.south && lot.bounds.east > square.bounds.west && lot.bounds.west < square.bounds.east){
          return true
        }else{
          return false
        }
      }).map(lot => lot.polygon)

      const overlapObj = calculateSquareLotsOverlapMask(square.polygon, overlappingLotPolygons)
      var squareOverlapObj = null
      //if the type is a multipolygon, set the mask to the multipolygon
      if(overlapObj.geometry.type == "MultiPolygon"){
        squareOverlapObj = overlapObj.geometry.coordinates.map(coords => coords[0])
      }else if(overlapObj.geometry.type == "Polygon"){
        squareOverlapObj = overlapObj.geometry.coordinates
      }


      //only displays gifsthat intersect with the active lots in the square
      var visibleGifs = []
      for (let index = 0; index < numberOfGifSections; index++) {
        var intersects = true;
        try{
          //north and south are calculated as the bounds of the square but the index  between 0 and 9 is used to determine the bounds of the gif
          const offsetAmount = squareSize / numberOfGifSections     
          const north = currentSquareObjectRef.current.bounds.north - (offsetAmount * index)
          const south = north - offsetAmount
          const west = currentSquareObjectRef.current.bounds.west
          const east = currentSquareObjectRef.current.bounds.east              

          //create turf polygon from bounds
          const squarePolygonArray = [[west, north],[east, north],[east, south],[west, south],[west, north]]
          intersects = calculatePolygonsIntersect(squarePolygonArray, squareOverlapObj)
        }catch(err){
          console.error(err)
        }

        if(intersects){
          visibleGifs.push(index)
        }

      }

      const adminGifsRef = ref(storage, `AdminData/${_squareId}/Imagery/Gifs/${_analyticId}`)
      listAll(adminGifsRef).then((res) => {      
        const promiseArray = []
        const itemRefArray = []
        res.items.forEach((itemRef) => {
          //get the ddwnload url for the item
          promiseArray.push(getDownloadURL(itemRef))
          itemRefArray.push(itemRef)
        })

        Promise.all(promiseArray).then((downloadElements) => {
          var gridUrlObj = {}
          downloadElements.forEach((url, index) => {
            try{
              const itemRef = itemRefArray[index]
              const gridId = itemRef.name.split('.')[0].split('-')[itemRef.name.split('.')[0].split('-').length - 1]
              if(visibleGifs.includes(parseInt(gridId))){
                gridUrlObj[gridId] = url
              }
            }catch(err){
              console.error(err)
            }
          })
          squareGifImageryRef.current[_squareId][_analyticId].elementUrls = gridUrlObj
          squareGifImageryRef.current[_squareId][_analyticId].loaded = true
          setSquareGifImagery(squareGifImageryRef.current)          
        })
      })
    }
  }

  function getSquarePngImagery(_squareId){
    return new Promise((resolve, reject) => {
      if(_squareId != null){
        //if the square imagery has already been loaded do not get it again
        if(squarePngImageryRef.current[_squareId] == null){
        
          squarePngImageryRef.current[_squareId] = {}

          //list all the files in the square/Pngs folder
          const adminPngsRef = ref(storage, `AdminData/${_squareId}/Imagery/Pngs`)
          listAll(adminPngsRef).then((res) => {      
            const promiseArray = []
            const itemRefArray = []
            res.items.forEach((itemRef) => {
              //get the ddwnload url for the item
              promiseArray.push(getDownloadURL(itemRef))
              itemRefArray.push(itemRef)
            })

            Promise.all(promiseArray).then((downloadElements) => {

              downloadElements.forEach((url, index) => {

                const itemRef = itemRefArray[index]

                const squareDateId = itemRef.name.split('.')[0]
                const squareId = _squareId

                const dateId = squareDateId.split(squareId)[1].slice(1)
                
                const endYear = dateId.split('-')[3]
                const endMonth = dateId.split('-')[4]
                const endDay = dateId.split('-')[5]

                const endDate = new Date(Date.UTC(endYear, endMonth - 1, endDay))
                const endDateString = formatDateAsId(endDate)


                squarePngImageryRef.current[_squareId][endDateString] = {
                  url: url,
                  squareId: squareId,
                  dateId: endDateString,                  
                  endDate: new Date(Date.UTC(endYear, endMonth - 1, endDay)),
                  displayDate: dateIdToUTCDateObject(endDateString),
                  analytics: {
                    "monthly": dateIdToAnalyticsRequestId(squareId, "monthly", dateId),
                  },
                  gifs: {
                    "monthly": dateIdToAnalyticsRequestIdShorthand("monthly", dateId)
                  },
                  loaded: false
                }

              })

              setCurrentSquarePngDates(squarePngImageryRef.current[_squareId]) 
              resolve()

            }).catch((error) => {
              console.error(error)   
              reject()         
            });

          })
          .catch((error) => {
            console.error(error)          
            reject()
          })

        }else{        
          if(typeof squarePngImageryRef.current[_squareId] == 'object'){        
            setCurrentSquarePngDates(squarePngImageryRef.current[_squareId])
            resolve()
          }else{
            reject()
          }
        }
      }else{
        resolve()
      }
    })    
  }

  function getSquareAnalyticsData(_squareId, _analyticRequestId, _urlObject){

    var numberOfElements = {
      total: Object.keys(_urlObject).length,
      success: 0,
      error: 0
    }
    
    const promiseArray = []
    const promiseKeyArray = []
    try{
      Object.keys(_urlObject).forEach(key => {
        const keyString = `${key}`

        fetch(_urlObject[keyString])
        .then(response => response.json())
        .then(data => {
    
          if(data?.metaData?.squareLatLngs != null){
            //generate an array from the squareLatLngs object
            data.metaData.squareLatLngs.latArray = Object.values(data.metaData.squareLatLngs.lats)
            data.metaData.squareLatLngs.lngArray = Object.values(data.metaData.squareLatLngs.lngs)
          }
    
          squareAnalyticsRef.current[_squareId][_analyticRequestId]["data"][key] = data
          
          numberOfElements.success += 1
          numberOfElements.total -= 1
    
          
        }).catch(error => {
              numberOfElements.error += 1
              numberOfElements.total -= 1      
              console.error(error)      
        }).finally(() => {
          if(numberOfElements.total == 0){
            //if the currentUserId is not in the _urlObject then make a copy of the Default
            if(_urlObject[activeUserId] == null && squareAnalyticsRef.current[_squareId][_analyticRequestId]["data"]["Default"] != null){
              const objectCopy = Object.assign({}, JSON.parse(JSON.stringify(squareAnalyticsRef.current[_squareId][_analyticRequestId]["data"]["Default"])))
              //update user specific variables
              objectCopy.metaData.displayName = user.displayName
              objectCopy.metaData.createdBy = activeUserId
              objectCopy.dateUpdated = (new Date()).getTime()
              
              //clear redundant data
              objectCopy.changes = {}
              delete objectCopy.meanChangeArray
              delete objectCopy.metaData.squareLatLngs
              squareAnalyticsRef.current[_squareId][_analyticRequestId]["data"][activeUserId] = objectCopy
            }
    
    
            //all elements have been processed
            createAlert('success', numberOfElements.error > 0 ? `Analytics Downloaded: ${numberOfElements.success} Success, ${numberOfElements.error} Error`: `Analytics Downloaded: ${numberOfElements.success} Success`)
            selectAnalytic(_squareId, _analyticRequestId) 
            setSquareAnalytics(squareAnalyticsRef.current)

          }
        })
      })
    }catch(err){
      console.error(err)
      createAlert("error", "Error pulling analytic data")
    }

  }

  function getSquareAnalyticsStatus(_squareId, _analyticRequestId){
    return new Promise(async (resolve, reject) => {
      try{
        if(_squareId != null && _analyticRequestId != null){
          //if the square analytics has already been loaded do not get it again
          if(squareAnalyticsRef.current[_squareId] == null){
            squareAnalyticsRef.current[_squareId] = {}
          }

          if(squareAnalyticsRef.current[_squareId][_analyticRequestId] == null){

            var updateObj = {
              urls: {},
              exists: false,
              data: null
            }

            const analyticId = _analyticRequestId.substring(14, _analyticRequestId.length)
            //check if the default analytic exists
            const adminDataFolder = `AdminData/${_squareId}/Analytics/DetectedChanges/${analyticId}`          
            // if it exists then list all of the files in the folder and get their urls
            const res = await listAll(ref(storage, adminDataFolder))
            //iterate over all the items in the folder
            const promiseArray = []
            const promiseKeyArray = []
            

            res.items.forEach(async (itemRef) => {
              //get the name and the url of the item
              const name = itemRef.name.split('.')[0].split('-')[itemRef.name.split('.')[0].split('-').length - 1]
              promiseArray.push(getDownloadURL(itemRef))       
              promiseKeyArray.push(name)
            })

            Promise.allSettled(promiseArray).then((promises) => {

              //foreach response
              promises.forEach((promise, index) => {
                if(promise.status == "fulfilled" && typeof promise?.value == "string"){
                  updateObj.urls[promiseKeyArray[index]] = promise.value
                  updateObj.exists = true
                }
              })
              squareAnalyticsRef.current[_squareId][_analyticRequestId] = updateObj;
              setSquareAnalytics(squareAnalyticsRef.current)
              resolve()
            })
          }else{
            resolve()
          }
        }else{
          resolve()
        }
      }catch(err){
        console.error(err)
        reject()
      }
    })
  }

  function renderDateSlider(_currentSquarePngDates){

    if(_currentSquarePngDates != null){
      const dateArray = Object.values(_currentSquarePngDates).map(dateObj => dateObj.displayDate.getTime())
      const minDate = Math.min(...dateArray)
      const maxDate = Math.max(...dateArray)
      setDateSliderMinDate(minDate)
      setDateSliderMaxDate(maxDate)

      const tempDateSliderMarks = Object.values(_currentSquarePngDates).map((dateObj) => {

        //if the date is january 1st, display the year
        var label = ""
        //get utc date
        if(dateObj.displayDate.getUTCMonth() == 0){
          label = `${dateObj.displayDate.getUTCFullYear()}`
        }/*else if (dateObj.displayDate.getUTCMonth() == 3){
          label = "Q2"
        }else if (dateObj.displayDate.getUTCMonth() == 6){
          label = "Q3"
        }else if (dateObj.displayDate.getUTCMonth() == 9){
          label = "Q4"
        }*/

        return {
          value: dateObj.displayDate.getTime(),
          label: label
        }
      })


      dateSliderMarksRef.current = tempDateSliderMarks
      setDateSliderMarks(tempDateSliderMarks)

      //if the dateSliderValue is null, or the value is not one of the dates in the currentSquarePngDates object, set the value to the maxDate
      if(dateSliderValue == null || !dateArray.includes(dateSliderValue)){
        handleDateSliderChange(maxDate)
      }else{
        //if changing from one square to another but with the same date get the new squares info using the dateslider change
        handleDateSliderChange(dateSliderValue)
      }

    }

  }

  function selectSquare(_squareId){
    return new Promise(async (resolve, reject) => {
      //only update the selected square if it's different than the current and the user is not processing an analytic
      if(_squareId != currentSquareRef.current && processingAnalyticIdRef.current == null){

        //resets everything
        setCurrentSquare(null)
        
        //instantiates the square
        currentSquareRef.current = _squareId
        setCurrentSquare(_squareId)
        const square = squaresRef.current[_squareId]

        if(_squareId != null && square != null){
          
          const overlappingLotPolygons = Object.values(lotsRef.current).filter(lot => {
            // check if bounds intersect with square bounds
            if(lot.bounds.south < square.bounds.north && lot.bounds.north > square.bounds.south && lot.bounds.east > square.bounds.west && lot.bounds.west < square.bounds.east){
              return true
            }else{
              return false
            }
          })
          .map(lot => lot.polygon)

          var squareOverlapObj = null
          var squareNonOverlapObj = null

          const overlapObj = calculateSquareLotsOverlapMask(square.polygon, overlappingLotPolygons)
          const nonOverlapObj = calculateSquareLotsOverlap(square.polygon, overlappingLotPolygons)
          

          //if the type is a multipolygon, set the mask to the multipolygon
          if(overlapObj.geometry.type == "MultiPolygon"){
            squareOverlapObj = overlapObj.geometry.coordinates
          }else if(overlapObj.geometry.type == "Polygon"){
            squareOverlapObj = [overlapObj.geometry.coordinates]
          }

          if(nonOverlapObj.geometry.type == "MultiPolygon"){
            squareNonOverlapObj = nonOverlapObj.geometry.coordinates
          }else if(nonOverlapObj.geometry.type == "Polygon"){
            squareNonOverlapObj = [nonOverlapObj.geometry.coordinates]
          }      

          setCurrentSquareLotMask(squareNonOverlapObj)
          currentSquareLotOverlapRef.current = squareOverlapObj
          currentSquareObjectRef.current = square
          setCurrentSquareObject(square)


          await getSquarePngImagery(_squareId)
          await getSquareGifStatus(_squareId)
          
        }

        requestViewportRender()
        resolve()
      }else{
        resolve()
      }
    })
  }

  function toggleGoogleMapType(_type = null){

    if(_type == 'roadmap' || _type == 'satellite'){
      setMapTypeId(_type)
    }else{      
      setMapTypeId(prev => prev === 'satellite' ? 'roadmap' : 'satellite');
    }
  }
  
  function handleDateSliderChange(_value){

    return new Promise(async (resolve, reject) => {
      try{
        //find the element in the squareImageryRef.current[selectedSquareRef] that has the displayDate.getTime() == _value
        const selectedSquarePngDates = squarePngImageryRef.current[currentSquareRef.current]
        if(selectedSquarePngDates == null){
          reject()
        }
        const selectedSquarePngDatesArray = Object.values(selectedSquarePngDates)
        const selectedSquarePngDate = selectedSquarePngDatesArray.find((dateObj) => dateObj.displayDate.getTime() == _value)


        if(selectedSquarePngDate != null){

          try{
            currentSquarePngDateObjectRef.current = selectedSquarePngDate
            setCurrentSquarePngDateObject(selectedSquarePngDate)
            squarePngImageryRef.current[currentSquareRef.current][selectedSquarePngDate.dateId].loaded = true
            
            const tempCurrentSquareVisiblePngs = Object.values(squarePngImageryRef.current[currentSquareRef.current]).filter((dateObj) => {
              return dateObj.loaded
            })

            //check if the state has changed
            if(JSON.stringify(currentSquareVisiblePngs) !== JSON.stringify(tempCurrentSquareVisiblePngs)){
              setCurrentSquareVisiblePngs(tempCurrentSquareVisiblePngs)
            }

            //check for analytics if not currently processing one
            if(processingAnalyticId == null){
              await getSquareAnalyticsStatus(currentSquareRef.current, selectedSquarePngDate.analytics.monthly)
            }
            setDateSliderValue(_value)
            setActiveDateObject(currentSquareVisiblePngs?.find((element) => element.displayDate.getTime() == dateSliderValue))
            getSquareGifImagery(currentSquare)
            setTimeout(() => {
              setRefreshKey(refreshKey + 1)
              
            }, 500);
            resolve()
          }catch(err){
            console.error(err)
            createAlert('error', "Error setting date")
            reject()
          }

        }else{
          createAlert('error', 'Date not found')
          reject()
        }
      }catch(err){
        console.error(err)
        createAlert('error', 'Error setting date')
        reject()
      }
    });

  }

  if(!isLoaded){
    return <>
          <div className='pageContent'>       
            <div className='adminMapContainerDiv'> 
              
            </div>
          </div>
          </>
  }


  function stackAnalyticFiles(_dataObject){
    var tempChanges = {}
    try{
      tempChanges = Object.assign({}, JSON.parse(JSON.stringify(_dataObject?.Default?.changes)))
    }catch(err){
      console.error(err)
    }
    try{          
      //if there is a key for the userId then iterate over the changes and stack them
      Object.entries(_dataObject?.[activeUserId]?.changes).forEach((entry) => {
        const key = entry[0]
        const value = entry[1]

        tempChanges[key] = Object.assign({}, JSON.parse(JSON.stringify(value)))
      })
    }catch(err){
      console.error(err)
    }
    try{          
      if(_dataObject?.["Official"]?.changes != null){
        //if there is a key for the userId then iterate over the changes and stack them
        Object.entries(_dataObject?.["Official"]?.changes).forEach((entry) => {
          const key = entry[0]
          const value = entry[1]
          tempChanges[key] = Object.assign({}, JSON.parse(JSON.stringify(value)))
        })
      }
    }catch(err){
      console.error(err)
    }
    
    Object.keys(tempChanges).forEach((key) => {
      if(tempChanges[key].bounds == null){
        tempChanges[key].bounds = calculateProcessingGridSquareBounds(key, squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData, currentSquareObjectRef.current.bounds)
      }
    })

    return tempChanges

  }

  function selectAnalytic(_squareId, _analyticRequestId){
    //verify the analytic exists in the squareAnalytics object
    if(squareAnalyticsRef.current?.[_squareId]?.[_analyticRequestId]?.exists == true ){
      // if the data is null
      if(squareAnalyticsRef.current?.[_squareId]?.[_analyticRequestId]?.data == null){      
        squareAnalyticsRef.current[_squareId][_analyticRequestId].data = {}
        const urlObject = squareAnalyticsRef.current?.[_squareId]?.[_analyticRequestId]?.urls
        getSquareAnalyticsData(_squareId, _analyticRequestId, urlObject)
      }else if(squareAnalyticsRef.current?.[_squareId]?.[_analyticRequestId]?.data?.Default != null){


        processingAnalyticIdRef.current = _analyticRequestId

        //stack the data from the squareAnalytics data object 
        const tempChanges = stackAnalyticFiles(squareAnalyticsRef.current?.[_squareId]?.[_analyticRequestId]?.data)
        
        //TODO: if there is a key for the official changes then iterate over the changes and stack them
        processingAnalyticChangesRef.current = tempChanges
        setProcessingAnalyticChanges(tempChanges)
        
        
        //render the processing grid
        calculateProcessingGridMetaSquareAssignment(processingAnalyticChangesRef.current)
        setProcessingAnalyticId(processingAnalyticIdRef.current)
        const tempProcessingAnalyticShorthand = processingAnalyticIdRef.current.substring(14, processingAnalyticIdRef.current.length)
        processingAnalyticIdShorthandRef.current = tempProcessingAnalyticShorthand
        setProcessingAnalyticIdShorthand(tempProcessingAnalyticShorthand)

        getSquareGifImagery(_squareId, tempProcessingAnalyticShorthand)

        processingAnalyticLastSavedState.current = JSON.stringify(tempChanges)
        
        
        
      }else{
        createAlert('error', 'Error getting analytic data')
        clearSquareState()
      }
    }
  }

  function attemptToExitProcessing(){
    //save the current state of the square
    //exit the current state of the

    // if there are changes since last save
    if(JSON.stringify(processingAnalyticChangesRef.current) != processingAnalyticLastSavedState.current){
      setConfirmExitWithoutSavingDialogOpen(true)
    }else{
      setConfirmExitWithoutSavingDialogOpen(false)
      clearProcessingAnalyticState()

      if(reviewMode == "reviewMode"){
        //do nothing
      }else if(reviewMode == "taskMode"){
        navigate('/Tasking')
      }else if(reviewMode == "validateMode"){
        navigate('/Tasking')
      }

      
    }
  }

  async function saveProcessingAndExit(){

    saveProcessing(true, false)
    .then(() => {
      attemptToExitProcessing()
    })
    .catch((error) => {
      console.error(error)
    })
    

  }

  function getRectangleOptions(_color){
    return {
      fillColor: _color,
      strokeColor: _color,
      strokeWeight: 1,
      fillOpacity: .2,
      opacity: 1,
    }
  }

  function saveProcessing(_manual, _publish){
    setSavingChangesLoading(true)
    return new Promise((resolve, reject) => {
      try{
    
        const userChanges = {} 
        
        Object.entries(processingAnalyticChangesRef.current).forEach((entry) => {
          try{
            const key = entry[0]
            const value = entry[1]
            // if the entry has a set
            if(value.a != null){
              userChanges[key] = Object.assign({}, JSON.parse(JSON.stringify(value)))
              
                delete userChanges[key]?.bounds
              
            }
          }catch(err){
            console.error(err)
          }
        });

        squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current]['data'][activeUserId].changes = userChanges

        const analyticId = processingAnalyticIdRef.current.substring(14, processingAnalyticIdRef.current.length)
        //save the changes to the database
        const adminDataFolder = `AdminData/${currentSquareRef.current}/Analytics/DetectedChanges/${analyticId}` 

        const documentId = _publish ? "Official":activeUserId

        const detectedChangesUserUpdatedRef = ref(storage, `${adminDataFolder}/${processingAnalyticIdRef.current}-${documentId}.json`)

        const blob = new Blob([JSON.stringify(squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current]['data'][activeUserId])], {type: 'application/json'});

        //upload the training data to storage as a json file
        uploadBytes(detectedChangesUserUpdatedRef, blob)
        .then((snapshot) => {
          if(_manual){
            if(_publish){
              createAlert('success', "Changes Saved and Published")
            }else{
              createAlert('success', "Changes Saved")
            }
          }        
          processingAnalyticLastSavedState.current = JSON.stringify(processingAnalyticChangesRef.current)
          setProcessingAnalyticSaveNecessary(false)
          resolve("Changes saved")

        })
        .catch((error) => {
          console.error(error)
          if(_publish){
            createAlert('error', "There was an error saving and publishing")     
            reject("Error saving and publishing")   
          }else{
            createAlert('error', "There was an error saving the changes")        
            reject("Error saving changes")
          }
        })
        .finally(() => {
          setSavingChangesLoading(false)  
        });
      }catch(err){
        console.error(err)
        if(_publish){
          createAlert('error', 'Error saving changes')
        }
        setSavingChangesLoading(false)
        reject("Error saving changes")
      }
    })
  }

  function publishChanges(){
    
    saveProcessing(false, true)
    .then(() => {

      //submit a publish request to the database
      //operations/LotActions/PublishSquareChanges
      const publishRef = firebase.firestore().collection("Operations").doc("LotActions").collection("PublishSquareAnalytics")      

      publishRef.add({
        squareId: currentSquareRef.current,
        analyticId: processingAnalyticIdShorthandRef.current,
        userId: user.uid,
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        requestScope: "all",
        specificLots: [],
      })
      .then(() => {
        createAlert('success', 'Publish request submitted')
        //close the publish dialog
        openPublishDialog(false)

        //if the user is in validateMode then close the task
        if(reviewMode == "validateMode" || reviewMode == "taskMode"){
          setTimeout(() => {
            openCompleteAnalyticProcessingDialog(true)          
          }, 500);
        }

      })
      .catch((error) => {
        createAlert('error', 'Error submitting publish request')
        console.error(error)
      })
    })
    .catch((error) => {
      console.error(error)
      createAlert('error', 'Error saving changes')
    })


  }

  function submitChangesForReview(){

    saveProcessing(true, false)
    .then(() => {
      openPublishDialog(false)
      
      const taskRef = firebase.firestore().collection("Operations").doc("LotActions").collection("AnalyticRequestActions")

        const updateObject = {
            userId: user.uid,
            action: "submitChangesForReview",
            requestId: propsTaskId,
            squareId: propsSquareId,
            payload: {
                numberOfElements: Object.values(processingAnalyticChangesRef.current).filter(e => e.a == true).length,
                reviewer: {
                  uid: activeUserId,
                }
            },
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        }

        taskRef.add(updateObject)
        .then(() => {
            createAlert("success", "Changes submitted for review")
            navigate('/Tasking')
        })
        .catch((error) => {
            createAlert("error", "Error starting task")
            console.error("Error writing document: ", error);
        })
        .finally(() => {

        })

    })
    .catch((error) => {
      console.error(error)
      
    })

  }

  function closeAnalyticTask(){

    console.log("closeAnalyticTask")
    const taskRef = firebase.firestore().collection("Operations").doc("LotActions").collection("AnalyticRequestActions")
    const updateObject = {
      userId: user.uid,
      action: "closeAnalyticTask",
      requestId: propsTaskId,
      squareId: propsSquareId,
      payload: {},
      dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
  }

  taskRef.add(updateObject)
  .then(() => {
      createAlert("success", "Task closed")
      navigate('/Tasking')
  })
  .catch((error) => {
      createAlert("error", "Error closing task")
      console.error("Error writing document: ", error);
  })

  }

  function calculateProcessingGridMetaSquareFromPixelId(_pixelId){

    try{
      const xCord = parseInt(_pixelId.split('-')[0])
      const yCord = parseInt(_pixelId.split('-')[1])
      const metaData = squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData
      const imgHeight = metaData.height
      const imgWidth = metaData.width

      //based on the image height and width and the processingGridSize divide the image into a grid where 00 is the top left corner and processingGridSize-1, processingGridSize-1 is the bottom right corner
      //return the grid key
      const xGridKey = Math.floor(xCord / (imgWidth / processingGridSize))
      const yGridKey = Math.floor(yCord / (imgHeight / processingGridSize))

      return `${xGridKey}${yGridKey}`
    }catch(err){
      console.error(err)
      return "00"
    }

  }


  function calculateProcessingGridMetaSquareAssignment(_dataObj){

    const tempGridObj = {}

    Object.entries(_dataObj).forEach((entry) => {
      const key = entry[0]
      const value = entry[1]
      const renderGridKey = calculateProcessingGridMetaSquareFromPixelId(key)
      if(tempGridObj[renderGridKey] == null){
        tempGridObj[renderGridKey] = {}
      }
      tempGridObj[renderGridKey][key] = value
    })

    Object.keys(tempGridObj).forEach((key) => {
      renderProcessingGridSubSquare(key, tempGridObj[key])
    })

  }

  function renderProcessingGridSubSquare(_gridKey, _dataObj){
    try{
      processingAnalyticGrid[_gridKey][1](_dataObj)
    }catch(err){
      console.error(err)
    }
  }

  function handleProcessingAnalyticClick(e){
    try{
      if(processingAnalyticIdRef.current != null){

        setProcessingAnalyticSaveNecessary(true)

        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        const metaData = squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData

        const latIndex = findClosestObjectId(metaData.squareLatLngs.latArray, lat, "lat") * metaData.numberOfPixels
        const lngIndex = findClosestObjectId(metaData.squareLatLngs.lngArray, lng, "lng") * metaData.numberOfPixels

        const pixelId = `${lngIndex}-${latIndex}`
        const status = !(processingAnalyticChangesRef.current?.[pixelId]?.a == true) ? true:false
        var element = processingAnalyticChangesRef.current?.[pixelId] || {a: null, l: []}
        element.a = status
        element.bounds = calculateProcessingGridSquareBounds(pixelId, squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData, currentSquareObjectRef.current.bounds)

        //update the procesingAnalyticChangesRef
        processingAnalyticChangesRef.current[pixelId] = element
        setProcessingAnalyticChanges(processingAnalyticChangesRef.current)

        // find which grid the lat and lng are in based on the height and width of the image / processingGridSize
        const renderGridKey = calculateProcessingGridMetaSquareFromPixelId(pixelId)

        // check if the update needs to cause a re-render of the processing grid or if it can be added to the processingBuffer
        // if it requires a grid re-render the update affected processing grid

        // if it can be added to the processingBuffer, add it to the processingBuffer and render the processingBuffer
        // else the processingBuffer is full for a given grid, update the grid and clear the buffer for that grid
        
        const copyObj = {...processingAnalyticGrid[renderGridKey][0]}
        copyObj[pixelId] = element
        renderProcessingGridSubSquare(renderGridKey, copyObj)

      }
    }catch(err){
      console.error(err)
      createAlert('error', 'Error processing analytic click')
    }
  }

  function renderGridMetaSquareRectangle(_metaSquareId, _squareId, _dataObj){
    try{
      if(_dataObj != null){
        if(_dataObj?.a == true){        
          return <Rectangle
                  key={`${_metaSquareId}-${_squareId}`}
                  editable={false}
                  draggable={false}
                  bounds={_dataObj?.bounds}    
                  options={getRectangleOptions(theme.palette.secondary.main)}/>
        }else if(_dataObj?.a == false){
          return null
        }else if(_dataObj?.ml == true){
          return <Rectangle
                  key={`${_metaSquareId}-${_squareId}`}
                  editable={false}
                  draggable={false}
                  bounds={_dataObj?.bounds}    
                  options={getRectangleOptions(theme.palette.red.main)}/>
        }
      }else{
        return null
      }
    }catch(err){
      console.error(err)
      return null
    }


  }

  const squareAnalyticsGifObj = squareGifImagery?.[currentSquare]?.[processingAnalyticIdShorthand]

  return (
    <>
    <div className='mapViewContent'>       
      <div className='adminMapContainerDiv'>   

          <GoogleMap           
            zoom={mapZoom} 
            center={location}             
            mapContainerClassName="adminMapViewBody"
            options={{
              styles: googleMapsStyle,
              disableDoubleClickZoom: true,

            }}
            onLoad={mapHandleOnLoad}
            onBoundsChanged={() => mapHandleBoundsChanged()}
            mapTypeId={mapTypeId}
            onClick={(e)=> { 
              selectSquare(null)
            }}
            onDblClick={(e)=> {                                       
              
            }}>
              <StreetViewPanorama
                onLoad={onStreetViewLoad}
                visible={mapIsStreetViewVisible}
                options={{
                  enableCloseButton: false, // Remove the close button
                  addressControl: false,    // Remove the address info box
                  panControl: false,        // Remove the pan control
                  zoomControl: false,       // Remove the zoom control
                  fullscreenControl: false, // Remove the fullscreen control
                  motionTrackingControl: false, // Remove motion tracking control
                }}>

              </StreetViewPanorama>
              {
                visibleLotsArray.map(key => {   
                  const lot = lotsRef.current[key]
                                             
                  return lot ? (
                    <Polygon
                      key={`${key}-polygon`}
                      editable={false}
                      draggable={false}
                      paths={lot.polygon}   

                      options={{
                        ...settings.rectangleOptions,
                        fillColor: "#4CAF50",
                        strokeColor: "#4CAF50",
                        strokeWeight: 3,
                        zIndex: 100,
                        fillOpacity: .5,
                      }}/>
                  ):null
                })
              }
              {
                visibleSquaresArray.map(key => {
                  const square = squaresRef.current[key]

                  return square ? (
                    <Polygon
                      key={`${key}-square`}
                      editable={false}
                      draggable={false}
                      paths={square.polygon}   
                      onClick={() => selectSquare(key)}
                      options={{
                        ...settings.rectangleOptions,
                        fillColor: theme.palette.primary.main,
                        strokeColor: theme.palette.secondary.main,
                        strokeWeight: 3,
                        zIndex: 102,
                        fillOpacity: 0,
                      }}/>
                  ):null

                })
              }
              {
                currentSquareVisiblePngs ? currentSquareVisiblePngs.map((pngObj) => {
                  try{
                    return <GroundOverlay
                              key={`currentSquareVisiblePngs-${pngObj.dateId}`}
                              url={pngObj.url}
                              bounds={{
                                north: currentSquareObject.bounds.north,
                                south: currentSquareObject.bounds.south,
                                east: currentSquareObject.bounds.east,
                                west: currentSquareObject.bounds.west
                              }}
                              opacity={mapPanoramaIsDragging ? .5 : (["png", "gif"].includes(currentSquareSelectedImageryType) && pngObj.displayDate.getTime() == dateSliderValue) ? 1:0}
                              options={{
                                clickable: false,
                                zIndex: 200,                                
                              }}
                            />
                  }catch(err){
                    console.error(err)
                  }
                }):null
              }
              {
                
                squareAnalyticsGifObj != null && squareAnalyticsGifObj?.loaded == true && currentSquareSelectedImageryType == "gif" ? Object.keys(squareAnalyticsGifObj?.elementUrls).map((key) => {
                  const url = squareAnalyticsGifObj?.elementUrls[key]
                  const index = key                  
                  //north and south are calculated as the bounds of the square but the index  between 0 and 9 is used to determine the bounds of the gif
                  const offsetAmount = squareSize / numberOfGifSections                  
                  const north = currentSquareObject.bounds.north - (offsetAmount * index)
                  const south = north - offsetAmount
                  const west = currentSquareObject.bounds.west
                  const east = currentSquareObject.bounds.east
                  return <GroundOverlay 
                            key={`${currentSquare}-${processingAnalyticIdShorthand}-gif-${index}}`}
                            url={url}
                            bounds={{
                              north: north,
                              south: south,
                              east: east,
                              west: west
                            }}                                    
                            opacity={ mapPanoramaIsDragging ? .5 : 1}
                            zIndex={201}
                            options={{
                              zIndex: 201,                                 
                            }}/>
                }):null
              }
              {processingAnalyticGrid["00"][0] != null ? Object.entries(processingAnalyticGrid["00"][0]).map((entry)=> renderGridMetaSquareRectangle("00", entry[0],entry[1])):null}
              {processingAnalyticGrid["01"][0] != null ? Object.entries(processingAnalyticGrid["01"][0]).map((entry)=> renderGridMetaSquareRectangle("01", entry[0],entry[1])):null}
              {processingAnalyticGrid["02"][0] != null ? Object.entries(processingAnalyticGrid["02"][0]).map((entry)=> renderGridMetaSquareRectangle("02", entry[0],entry[1])):null}
              {processingAnalyticGrid["03"][0] != null ? Object.entries(processingAnalyticGrid["03"][0]).map((entry)=> renderGridMetaSquareRectangle("03", entry[0],entry[1])):null}
              {processingAnalyticGrid["04"][0] != null ? Object.entries(processingAnalyticGrid["04"][0]).map((entry)=> renderGridMetaSquareRectangle("04", entry[0],entry[1])):null}
              {processingAnalyticGrid["05"][0] != null ? Object.entries(processingAnalyticGrid["05"][0]).map((entry)=> renderGridMetaSquareRectangle("05", entry[0],entry[1])):null}
              {processingAnalyticGrid["10"][0] != null ? Object.entries(processingAnalyticGrid["10"][0]).map((entry)=> renderGridMetaSquareRectangle("10", entry[0],entry[1])):null}
              {processingAnalyticGrid["11"][0] != null ? Object.entries(processingAnalyticGrid["11"][0]).map((entry)=> renderGridMetaSquareRectangle("11", entry[0],entry[1])):null}
              {processingAnalyticGrid["12"][0] != null ? Object.entries(processingAnalyticGrid["12"][0]).map((entry)=> renderGridMetaSquareRectangle("12", entry[0],entry[1])):null}
              {processingAnalyticGrid["13"][0] != null ? Object.entries(processingAnalyticGrid["13"][0]).map((entry)=> renderGridMetaSquareRectangle("13", entry[0],entry[1])):null}
              {processingAnalyticGrid["14"][0] != null ? Object.entries(processingAnalyticGrid["14"][0]).map((entry)=> renderGridMetaSquareRectangle("14", entry[0],entry[1])):null}
              {processingAnalyticGrid["15"][0] != null ? Object.entries(processingAnalyticGrid["15"][0]).map((entry)=> renderGridMetaSquareRectangle("15", entry[0],entry[1])):null}
              {processingAnalyticGrid["20"][0] != null ? Object.entries(processingAnalyticGrid["20"][0]).map((entry)=> renderGridMetaSquareRectangle("20", entry[0],entry[1])):null}
              {processingAnalyticGrid["21"][0] != null ? Object.entries(processingAnalyticGrid["21"][0]).map((entry)=> renderGridMetaSquareRectangle("21", entry[0],entry[1])):null}
              {processingAnalyticGrid["22"][0] != null ? Object.entries(processingAnalyticGrid["22"][0]).map((entry)=> renderGridMetaSquareRectangle("22", entry[0],entry[1])):null}
              {processingAnalyticGrid["23"][0] != null ? Object.entries(processingAnalyticGrid["23"][0]).map((entry)=> renderGridMetaSquareRectangle("23", entry[0],entry[1])):null}
              {processingAnalyticGrid["24"][0] != null ? Object.entries(processingAnalyticGrid["24"][0]).map((entry)=> renderGridMetaSquareRectangle("24", entry[0],entry[1])):null}
              {processingAnalyticGrid["25"][0] != null ? Object.entries(processingAnalyticGrid["25"][0]).map((entry)=> renderGridMetaSquareRectangle("25", entry[0],entry[1])):null}
              {processingAnalyticGrid["30"][0] != null ? Object.entries(processingAnalyticGrid["30"][0]).map((entry)=> renderGridMetaSquareRectangle("30", entry[0],entry[1])):null}
              {processingAnalyticGrid["31"][0] != null ? Object.entries(processingAnalyticGrid["31"][0]).map((entry)=> renderGridMetaSquareRectangle("31", entry[0],entry[1])):null}
              {processingAnalyticGrid["32"][0] != null ? Object.entries(processingAnalyticGrid["32"][0]).map((entry)=> renderGridMetaSquareRectangle("32", entry[0],entry[1])):null}
              {processingAnalyticGrid["33"][0] != null ? Object.entries(processingAnalyticGrid["33"][0]).map((entry)=> renderGridMetaSquareRectangle("33", entry[0],entry[1])):null}
              {processingAnalyticGrid["34"][0] != null ? Object.entries(processingAnalyticGrid["34"][0]).map((entry)=> renderGridMetaSquareRectangle("34", entry[0],entry[1])):null}
              {processingAnalyticGrid["35"][0] != null ? Object.entries(processingAnalyticGrid["35"][0]).map((entry)=> renderGridMetaSquareRectangle("35", entry[0],entry[1])):null}
              {processingAnalyticGrid["40"][0] != null ? Object.entries(processingAnalyticGrid["40"][0]).map((entry)=> renderGridMetaSquareRectangle("40", entry[0],entry[1])):null}
              {processingAnalyticGrid["41"][0] != null ? Object.entries(processingAnalyticGrid["41"][0]).map((entry)=> renderGridMetaSquareRectangle("41", entry[0],entry[1])):null}
              {processingAnalyticGrid["42"][0] != null ? Object.entries(processingAnalyticGrid["42"][0]).map((entry)=> renderGridMetaSquareRectangle("42", entry[0],entry[1])):null}
              {processingAnalyticGrid["43"][0] != null ? Object.entries(processingAnalyticGrid["43"][0]).map((entry)=> renderGridMetaSquareRectangle("43", entry[0],entry[1])):null}
              {processingAnalyticGrid["44"][0] != null ? Object.entries(processingAnalyticGrid["44"][0]).map((entry)=> renderGridMetaSquareRectangle("44", entry[0],entry[1])):null}
              {processingAnalyticGrid["45"][0] != null ? Object.entries(processingAnalyticGrid["45"][0]).map((entry)=> renderGridMetaSquareRectangle("45", entry[0],entry[1])):null}
              {processingAnalyticGrid["50"][0] != null ? Object.entries(processingAnalyticGrid["50"][0]).map((entry)=> renderGridMetaSquareRectangle("50", entry[0],entry[1])):null}
              {processingAnalyticGrid["51"][0] != null ? Object.entries(processingAnalyticGrid["51"][0]).map((entry)=> renderGridMetaSquareRectangle("51", entry[0],entry[1])):null}
              {processingAnalyticGrid["52"][0] != null ? Object.entries(processingAnalyticGrid["52"][0]).map((entry)=> renderGridMetaSquareRectangle("52", entry[0],entry[1])):null}
              {processingAnalyticGrid["53"][0] != null ? Object.entries(processingAnalyticGrid["53"][0]).map((entry)=> renderGridMetaSquareRectangle("53", entry[0],entry[1])):null}
              {processingAnalyticGrid["54"][0] != null ? Object.entries(processingAnalyticGrid["54"][0]).map((entry)=> renderGridMetaSquareRectangle("54", entry[0],entry[1])):null}
              {processingAnalyticGrid["55"][0] != null ? Object.entries(processingAnalyticGrid["55"][0]).map((entry)=> renderGridMetaSquareRectangle("55", entry[0],entry[1])):null}
              {
                currentSquareObject?.polygon ? (() => {

                  return <Polygon
                            key={`currentSquarePolygon`}
                            editable={false}
                            draggable={false}
                            paths={currentSquareObject?.polygon}   
                            options={{
                              ...settings.rectangleOptions,

                              strokeColor: theme.palette.secondary.main,
                              strokeWeight: 3,
                              zIndex: 202,
                              fillOpacity: 0,                              
                            }}
                            onClick={(e) => {
                              handleProcessingAnalyticClick(e)
                            }}/>

                })():null
              }
              {
                currentSquareLotMask ? currentSquareLotMask.map((mask) => {

                  //sum of the abs of the lat and lng values of the mask
                  const sum = mask.reduce((acc, val) => {
                    return acc + Math.abs(val[0]) + Math.abs(val[1])
                  },0)

                  console.log(mask)

                  return <Polygon
                            key={`currentSquareLotMask-${sum}`}
                            editable={false}
                            draggable={false}
                            paths={mask.map(poly => poly.map(coordsArray => {
                                return {
                                  lat: coordsArray[1],
                                  lng: coordsArray[0]
                                }
                              })
                            )}   
                            options={{
                              ...settings.rectangleOptions,
                              fillColor: theme.palette.primary.main,
                              strokeColor: theme.palette.secondary.main,
                              strokeWeight: 3,
                              zIndex: 203,
                              fillOpacity: .75,
                              clickable: false,
                            }}/>

                }):null
              }
            
          </GoogleMap>
          <div className='adminMapContainerSliderDiv' style={{height: currentSquare != null ? '70px':'0px', opacity: currentSquare != null ? 1:0}} data-disable-interaction="true">       
            {
              ![currentSquarePngDates, dateSliderValue, dateSliderMarks, dateSliderMinDate, dateSliderMaxDate].includes(null) ? 
                <Slider
                  step={null}
                  value={dateSliderValue}
                  onChange={(e) => handleDateSliderChange(e.target.value)}
                  marks={dateSliderMarks}
                  min={dateSliderMinDate}
                  max={dateSliderMaxDate}
                  color="secondary"                     
                  disabled={Object.keys(currentSquarePngDates).length == 0 || currentSquare == null}     
                  style={{position: 'relative', }}     
                />:null
            }
          </div>

          {/* Overlay Elements */}            
          <Paper className='adminMapSelectAnalyticCard' key={`${refreshKey}-selectAnalyticCard`}>
            <div className='adminMapSelectAnalyticCardMapSelector'>
              {
                mapIsStreetViewVisible ? 
                  <Button 
                    variant={"text"}
                    color={"primary"}  
                    onClick={() => {
                      setMapIsStreetViewVisible(false);
                      setMapPanoramaIsDragging(false)
                    }}
                    style={{flex: 1, height: '45px', lineHeight: '45px'}}
                    startIcon={<ArrowCircleLeft />}>
                      Exit Street View
                  </Button>
                :<>
                  <Button 
                    variant={"text"}
                    color={mapTypeId == "roadmap" ? "primary":"gray"}  
                    onClick={() => {toggleGoogleMapType("roadmap")}}
                    style={{flex: 1, height: '45px', lineHeight: '45px'}}
                    >
                      Map
                  </Button>
                  <Button 
                    variant={"text"}
                    color={mapTypeId == "satellite" ? "primary":"gray"}  
                    onClick={() => {toggleGoogleMapType("satellite")}}
                    style={{ flex: 1, height: '45px', lineHeight: '45px'}}
                    >
                      Satellite
                  </Button>
                </>
              }
              

            </div>
            <div className='adminMapSelectAnalyticCardSquareInfo' style={{display: currentSquare != null ? 'flex':'none'}}>
              <h5 style={{height: '35px'}}>
                  {
                    ![taskObject, taskReviewIndexOfKey].includes(null) ? <>
                        <SelectAll /><span><div><div><strong>{reviewMode == "taskMode" ? "Map Task":reviewMode == "validateMode" ? "Map Validation":""}</strong></div><div>{`${(taskReviewIndexOfKey + 1).toString().padStart(3, "0")} - ${taskObject?.displayName}`}</div></div></span>
                    </>:<>
                        <SelectAll /><span>{processingAnalyticId == null ? `${currentSquare ? currentSquare:''}`:<div><div>{currentSquare}</div><div>{processingAnalyticId.substring(14, processingAnalyticId.length)}</div></div> }</span>
                    </>

                  }
                  
              </h5>
              <h5>
                <Event /><span><div><div><strong>Analytic Date</strong></div><div>{currentSquarePngDateObject ? dateToLocaleUTCDateString(currentSquarePngDateObject?.displayDate, 'en-us', {year: 'numeric', month: 'long'}):''}</div></div></span>
              </h5>
              {
                ![null, undefined].includes(taskReviewObject?.reviewer) ? <h5>
                  <img src={taskReviewObject?.reviewer?.photoURL} className='img'/><span><div><div><strong>Reviewer</strong></div><div>{taskReviewObject?.reviewer?.displayName}</div></div></span>
                </h5>:null
              }
              <h5>
                {
                  processingAnalyticChanges != null ? 
                  <><GridView /><span><div><div><strong>Elements</strong></div><div>{`${Object.values(processingAnalyticChanges)?.filter(e => e.a == true).length} Elements`}</div></div></span></>
                  :null
                }

              </h5>
              <div className='adminMapSelectAnalyticImagePickerArea'> 
                <IconButton
                  onClick={() => {
                    changeSelectedImgType("png")
                  }}                    
                  disabled={!currentSquareVisiblePngs}
                  style={{backgroundColor: currentSquareSelectedImageryType == "png" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                  <Collections style={{color: theme.palette.secondary.contrastText}}/>
                </IconButton>
                <IconButton
                  onClick={() => {
                    
                    changeSelectedImgType("gif")
                  }}
                  disabled={(processingAnalyticId == null)}
                  style={{backgroundColor: currentSquareSelectedImageryType == "gif" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                  <Gif style={{color: (processingAnalyticId == null || squareGifImagery?.[currentSquare]?.[activeDateObject?.gifs?.monthly] == null) ? theme.palette.gray.contrastText:theme.palette.secondary.contrastText}}/>
                </IconButton>
                <IconButton
                  onClick={() => {
                    changeSelectedImgType("none")
                  }}
                  style={{backgroundColor: currentSquareSelectedImageryType == null ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                  <HideImage style={{color: theme.palette.secondary.contrastText}}/>
                </IconButton>
              </div>
              <Button
                variant='contained'
                color='secondary'
                disabled={!(squareAnalytics?.[currentSquare]?.[currentSquarePngDateObject?.analytics?.monthly]?.exists == true)}
                onClick={() => {
                  selectAnalytic(currentSquare, currentSquarePngDateObject.analytics.monthly)
                }}
                style={{display: processingAnalyticId == null && !(["taskMode", "validateMode"].includes(reviewMode))? 'flex':'none'}}>
                {(squareAnalytics?.[currentSquare]?.[currentSquarePngDateObject?.analytics?.monthly]?.exists == true) ? 'Label Analytics':'No Analytics'}
              </Button>
            </div>
          </Paper>
          
          <Paper className='adminMapSaveAnalyticCard' style={{display: processingAnalyticId != null ? 'flex':'none'}} key={`${refreshKey}-saveChanges`}>              
            <Button
              variant='contained'
              color='secondary'
              onClick={async () => {
                if(processingAnalyticSaveNecessary){
                  saveProcessing(true, false)
                  .catch((error) => {
                    console.error(error)
                  })
                }else{
                  openPublishDialog(true)                    
                }

              }}
              startIcon={processingAnalyticSaveNecessary ? <Save />:(reviewMode == "taskMode" ? <CheckCircleOutline />:<CloudUpload />)}
              style={{minWidth: '190px'}}>
                {
                  processingAnalyticSaveNecessary ? 'Save Changes':(reviewMode == "taskMode" ? 'Submit Changes':'Publish Changes')
                }
            </Button>
            
          </Paper>

          <Paper className='adminMapExitAnalyticCard' style={{display: processingAnalyticId != null ? 'flex':'none'}} key={`${refreshKey}-endSession`}>              
            <Button
              variant='text'
              color='primary'
              onClick={() => {
                attemptToExitProcessing()
              }}
              startIcon={<ExitToApp />}
              >
              End Session 
            </Button>
          </Paper>
      </div>
    </div>
    <Dialog
        open={confirmExitWithoutSavingDialogOpen}
        onClose={() => setConfirmExitWithoutSavingDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Looks like you have unsaved changes"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please save your changes before exiting
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmExitWithoutSavingDialogOpen(false)}>Cancel</Button>
          
          <div style={{flex: 1}}></div>
          <Button 
            variant='contained'
            color='secondary'
            startIcon={<Save />}
            onClick={() => saveProcessingAndExit()} autoFocus>
            Save and Exit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={publishChangesDialogOpen}
        onClose={() => openPublishDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Publish Analytics?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will publish the changes to the square and make them live to our customers. Are you sure you want to publish these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => openPublishDialog(false)}>Cancel</Button>
          
          <div style={{flex: 1}}></div>

          <CountdownTimerButton 
            targetMilliseconds={publishChangesDialogOpenTime} 
            loading={savingChangesLoading}
            icon={<CloudUpload />} 
            text="Publish"
            action={() => {publishChanges()}}/>


        </DialogActions>
      </Dialog>

      <Dialog
        open={submitChangesDialogOpen}
        onClose={() => openPublishDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Submit Changes for Review?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will submit the changes to the square for review by a supervisor. Are you sure you want to submit these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => openPublishDialog(false)}>Cancel</Button>
          
          <div style={{flex: 1}}></div>

          <CountdownTimerButton 
            targetMilliseconds={publishChangesDialogOpenTime} 
            loading={savingChangesLoading}
            icon={<CheckCircleOutline />} 
            text="Submit"
            action={() => {submitChangesForReview()}}/>


        </DialogActions>
      </Dialog>

      <Dialog
        open={completeAnalyticProcessingDialogOpen}
        onClose={() => openCompleteAnalyticProcessingDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Close Map Review Task?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will mark this task as complete and remove it from the task list. Are you sure you want to close this task?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => openCompleteAnalyticProcessingDialog(false)}>Cancel</Button>
          
          <div style={{flex: 1}}></div>

          <CountdownTimerButton 
            targetMilliseconds={completeAnalyticProcessingDialogOpenTime} 
            loading={savingChangesLoading}
            icon={<CloseFullscreen />} 
            text="Close Task"
            action={() => {closeAnalyticTask()}}/>


        </DialogActions>
      </Dialog>

      

    </>
  )

}


export default AdminMapReview
